import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { GrowHeroContent } from "@components/GrowWithSales/Hero/Hero";
import { BenefitsContent } from "@components/WorkingAtRyerson/Benefits/Benefits";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Careers at Ryerson - Ryerson",
		description:
			"Discover a career at Ryerson. Search for open positions in your area. Inside Sales Reps, Operations, and more.",
		url: `/about-us/careers`,
		imageUrl: "/images/careers/CareersHeroThumbnailUpdate.png",
		company: true,
	},
	fr: {
		title: "Careers at Ryerson - Ryerson",
		description:
			"Discover a career at Ryerson. Search for open positions in your area. Inside Sales Reps, Operations, and more.",
		url: `/about-us/careers`,
		imageUrl: "/images/careers/CareersHeroThumbnailUpdate.png",
		company: true,
	},
	es: {
		title: "Careers at Ryerson - Ryerson",
		description:
			"Discover a career at Ryerson. Search for open positions in your area. Inside Sales Reps, Operations, and more.",
		url: `/about-us/careers`,
		imageUrl: "/images/careers/CareersHeroThumbnailUpdate.png",
		company: true,
	},
};

export const HeroContent: LanguageContent<GrowHeroContent> = {
	en: {
		title: "Careers",
		subTitle: "Be a part of history. Join our team.",
		description:
			"Join Ryerson if you're driven by enthusiasm, a passion for innovation, and the confidence to push boundaries. We welcome individual perspectives, fostering an environment where everyone can thrive. At Ryerson, we prioritize internal growth and offer ample resources for long-term career advancement.",
		videoId: "TA6muHDqS24",
		thumbnailUrl: "/images/careers/MetalExcellence.jpg",
		videoOverlayText: "Hear from Ryerson \n Team Members",
		buttonLabel: "View All Openings",
		buttonUrl: "/about-us/careers/open-positions/",
	},
	fr: {
		title: "Carrières",
		subTitle: "Faites partie de l’histoire. Joignez-vous à notre équipe.",
		description: `Joignez-vous à Ryerson si vous êtes motivé par l’enthousiasme, la passion pour l’innovation et la confiance nécessaire pour repousser les limites. Nous accueillons les perspectives individuelles, favorisant ainsi un environnement où chaque personne peut s’épanouir. Chez Ryerson, nous accordons la priorité à la croissance interne et offrons de nombreuses ressources pour le développement de carrière à long terme.`,
		videoId: "TA6muHDqS24",
		thumbnailUrl: "/images/careers/MetalExcellence.jpg",
		videoOverlayText: "Écoutez Ryerson \n Membres de l'équipe",
		buttonLabel: "AFFICHER TOUS LES POSTES DISPONIBLES",
		buttonUrl: "/about-us/careers/open-positions/",
	},
	es: {
		title: "Empleo",
		subTitle: "Sea parte de la historia. Únase a nuestro equipo.",
		description: `Únase a Ryerson si está impulsado por el entusiasmo, con una pasión por la innovación y con la confianza de superar los límites. Damos la bienvenida a perspectivas individuales, para fomentar un entorno donde todos puedan prosperar. En Ryerson, damos prioridad al crecimiento interno y ofrecemos una amplia variedad de recursos para su crecimiento de carrera a largo plazo.`,
		videoId: "TA6muHDqS24",
		thumbnailUrl: "/images/careers/MetalExcellence.jpg",
		videoOverlayText: "Escuche de Ryerson \n Miembros del equipo",
		buttonLabel: "VER TODAS LAS VACANTES",
		buttonUrl: "/about-us/careers/open-positions/",
	},
};

export const JoinBenefitsContent: LanguageContent<BenefitsContent> = {
	en: {
		title: "Benefits of Joining Our Team",
		disclaimer:
			"*Important Notice: Eligibility for certain benefits may vary depending on location, position, or existing collective bargaining agreements. We strive to offer a comprehensive benefits package, and we encourage you to discuss your specific eligibility with our HR team.",
		actionText: "Learn More about Benefits",
		actionUrl: "/about-us/careers/working-at-ryerson/",
		cards: [
			{
				title: "Health",
				imageUrl: "/images/careers/working-at-ryerson/StethoscopeIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/StethoscopeIcon.svg",
				benefits: [
					"Medical, Dental, and Vision plans for employees and their eligible dependents",
					"Company paid Basic Life insurance",
					"Company paid Short-term disability",
					"Healthcare Flexible Spending Account",
					"Health Savings Account with company contribution",
				],
				hasQuote: false,
			},
			{
				title: "Family",
				imageUrl: "/images/careers/working-at-ryerson/FamilyIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/FamilyIcon.svg",
				benefits: [
					"Dependent Care Flexible Spending Account",
					"Work/Life Assistance Program",
					"Paid Holidays",
					"Paid Vacation and Sick Time",
					"Paid Parental Leave",
				],
				hasQuote: false,
			},
			{
				title: "Finance",
				imageUrl: "/images/careers/working-at-ryerson/PiggyBankIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/PiggyBankIcon.svg",
				benefits: [
					"Competitive Pay",
					"Incentive Bonus Plan",
					"401(k)",
					"Hiring Referral Bonus",
				],
				hasQuote: false,
			},
			{
				title: "Culture",
				imageUrl: "/images/careers/working-at-ryerson/DiversityInclusionIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/DiversityInclusionIcon.svg",
				benefits: [
					"Career Advancement Opportunities",
					"Company Investment in Professional Development and Continuing Education ",
					"Casual Office Attire",
					"Ryerson-Sponsored Social Events",
					"We Support Our Local Communities",
					"Equal Opportunity Employer",
				],
				hasQuote: false,
			},
		],
	},
	fr: {
		title: "Avantages de rejoindre notre équipe",
		disclaimer:
			"*Important Notice: Eligibility for certain benefits may vary depending on location, position, or existing collective bargaining agreements. We strive to offer a comprehensive benefits package, and we encourage you to discuss your specific eligibility with our HR team.",
		actionText: "En savoir plus sur les avantages",
		actionUrl: "/about-us/careers/working-at-ryerson/",
		cards: [
			{
				title: "Santé",
				imageUrl: "/images/careers/working-at-ryerson/StethoscopeIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/StethoscopeIcon.svg",
				benefits: [
					"Régimes médicaux, dentaires et visuels pour les employés et leurs personnes à charge admissibles",
					"Assurance vie de base payée par l’entreprise",
					"Invalidité de courte durée payée par l’entreprise.",
					"Allocation de dépenses flexible pour les soins de santé",
					"Compte épargne santé avec apport de l’entreprise",
				],
				hasQuote: false,
			},
			{
				title: "Famille",
				imageUrl: "/images/careers/working-at-ryerson/FamilyIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/FamilyIcon.svg",
				benefits: [
					"Allocation de dépenses flexible pour les soins aux personnes à charge",
					"Programme d’aide aux employés",
					"Congés payés",
					"Congés payés et congés de maladie",
					"Congé parental payé",
				],
				hasQuote: false,
			},
			{
				title: "Finances",
				imageUrl: "/images/careers/working-at-ryerson/PiggyBankIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/PiggyBankIcon.svg",
				benefits: [
					"Salaire concurrentiel",
					"Régime de primes incitatives",
					"Régime 401(k)",
					"Prime pour recommandation à l’embauche",
				],
				hasQuote: false,
			},
			{
				title: "Culture",
				imageUrl: "/images/careers/working-at-ryerson/DiversityInclusionIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/DiversityInclusionIcon.svg",
				benefits: [
					"Possibilités de développement de carrière",
					"Investissement de l’entreprise dans le développement professionnel et la formation continue",
					"Tenue professionnelle décontractée",
					"Événements mondains commandités par Ryerson",
					"Nous soutenons nos communautés locales",
					"Employeur qui promeut l’égalité des chances",
				],
				hasQuote: false,
			},
		],
	},
	es: {
		title: "Beneficios de unirse a nuestro equipo",
		disclaimer:
			"*Important Notice: Eligibility for certain benefits may vary depending on location, position, or existing collective bargaining agreements. We strive to offer a comprehensive benefits package, and we encourage you to discuss your specific eligibility with our HR team.",
		actionText: "Obtenga más información sobre los beneficios",
		actionUrl: "/about-us/careers/working-at-ryerson/",
		cards: [
			{
				title: "Salud",
				imageUrl: "/images/careers/working-at-ryerson/StethoscopeIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/StethoscopeIcon.svg",
				benefits: [
					"Planes médicos, dentales y de visión para empleados y sus dependientes elegibles",
					"Seguro de vida básico proporcionado por la compañía",
					"Discapacidad a corto plazo proporcionada por la compañía",
					"Cuenta de gastos flexibles para servicios de salud",
					"Cuenta de ahorros para salud con contribución de la compañía",
				],
				hasQuote: false,
			},
			{
				title: "Familia",
				imageUrl: "/images/careers/working-at-ryerson/FamilyIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/FamilyIcon.svg",
				benefits: [
					"Cuenta de gastos flexibles para atención a dependientes",
					"Programa de asistencia trabajo/vida",
					"Días festivos pagados",
					"Vacaciones y licencia por enfermedad pagados",
					"Ausencia parental pagada",
				],
				hasQuote: false,
			},
			{
				title: "Finanzas",
				imageUrl: "/images/careers/working-at-ryerson/PiggyBankIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/PiggyBankIcon.svg",
				benefits: [
					"Pago competitivo",
					"Plan de bonos e incentivos",
					"Plan 401(k)",
					"Bono de contratación por referencia",
				],
				hasQuote: false,
			},
			{
				title: "Cultura",
				imageUrl: "/images/careers/working-at-ryerson/DiversityInclusionIcon.svg",
				mobileImageUrl: "/images/careers/working-at-ryerson/DiversityInclusionIcon.svg",
				benefits: [
					"Oportunidades de crecimiento profesional",
					"Inversión de la compañía en desarrollo profesional y educación continua",
					"Vestuario casual de oficina",
					"Eventos sociales patrocinados por Ryerson",
					"Apoyamos a nuestras comunidades locales",
					"Empleador que ofrece igualdad de oportunidades",
				],
				hasQuote: false,
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, JoinBenefitsContent],
};
