import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { PrivacyPolicyHeroContent } from "@components/Policies/PrivacyHero";
import { PrivacyPolicyBodyContent } from "@components/Policies/PrivacyBody";
import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Terms and Conditions - Ryerson",
		description: `Ryerson terms and conditions. We are online metal supplier, metal processor and distributor.`,
		url: `/terms-and-conditions`,
		company: true,
	},
	fr: {
		title: "Terms and Conditions - Ryerson",
		description: `Ryerson terms and conditions. We are online metal supplier, metal processor and distributor.`,
		url: `/terms-and-conditions`,
		company: true,
	},
	es: {
		title: "Terms and Conditions - Ryerson",
		description: `Ryerson terms and conditions. We are online metal supplier, metal processor and distributor.`,
		url: `/terms-and-conditions`,
		company: true,
	},
};

export const USContentNew: PrivacyPolicyBodyContent = {
	downloadLink:
		"/static-assets/documents/pdfs/en/policies-terms-conditions/TermsAndConditionsOfSale2025.pdf",
	downloadLabel: "Download Ryerson's U.S. Conditions and Terms of Sale",
	top: [
		`The following terms of sale shall be applicable to all sales made by <strong>RYERSON</strong>, as a d/b/a of Joseph T. Ryerson & Son, Inc., and its subsidiaries (“Ryerson”) except where it is otherwise expressly agreed in writing.
Acceptance of delivery of any or all goods ordered by a buyer (“Buyer”) shall constitute assent by the Buyer to these terms of sale collectively. All orders with a U.S. Government end-user, DPAS Ratings, export
control, or intended for nuclear applications must be pre-approved in writing by Ryerson.`,
	],
	list: {
		type: "ordered",
		listItems: [
			{
				title: "PRICES",
				body: [
					`The prices quoted may be changed by Ryerson without notice to reflect Ryerson’s prices
at time of shipment, and any increase in transportation, labor or other costs. If a delivered price has been
quoted, any charges at destination for spotting, switching, handling, storage and other accessorial
services and demurrage shall be borne by Buyer, and any increase or decrease in transportation charges
shall be added to or subtracted from the quoted price. Ryerson reserves the right to correct any errors
in specifications or prices.`,
				],
			},
			{
				title: "SUPPLIER SURCHARGES AND TAXES",
				body: [
					`Any and all surcharges imposed by Ryerson’s suppliers at the
time of shipment shall be paid by Buyer. All prices quoted by Ryerson are exclusive of all taxes. In addition
to the purchase price, Buyer shall pay or reimburse Ryerson the amount of all sales, use and ad valorem
taxes, excises, duties, tariffs, and/or other governmental charges, under any existing or future law, that
Ryerson may be required to pay with respect to the sale, purchase, delivery, storage, processing, use,
consumption or transportation of any of the goods or services covered. The foregoing shall not apply to
any taxes the payment or collection of which by Ryerson is excused by reason of delivery to Ryerson of
valid tax exemption certificates.`,
				],
			},
			{
				title: "QUANTITIES",
				body: [
					`If an item is to be shipped from a producing mill, the producing mill may reserve the
privilege of shipping over or under the ordered quantity in accordance with established percentages (as
shown in their price books), which will constitute acceptable and complete shipment of the material
specified.`,
				],
			},
			{
				title: "SALE ON CREDIT; TERMS OF PAYMENT",
				body: [
					`Ryerson reserves the right in its sole discretion to approve,
conditionally approve or disapprove any request by Buyer for credit. The amount of credit Ryerson
extends to Buyer will be determined by Ryerson in its discretion and may vary from time to time. Upon
Ryerson’s request from time to time, Buyer will provide Ryerson with current financial information.
Buyer grants permission to Ryerson to obtain independent credit reports and other information from
Buyer’s trade references and banks and authorizes credit references and banks to release information
that may be used to determine creditworthiness, both now and in the future. Buyer represents and
warrants that any financial information provided to Ryerson will be true and correct in all material
respects and shall fairly and accurately present the financial condition of Buyer as of the date of such
financial statements. Buyer shall notify Ryerson, in writing, of any error in any invoice within ten (10)
days after the Buyer’s receipt of such invoice, and, if no such notice from Buyer is received by Ryerson,
the invoice shall be deemed to be correct and payable as delivered to Buyer. Unless otherwise expressly
provided by Ryerson in writing, Ryerson’s payment terms will be net thirty (30) days from the date of
invoice by check or wire transfer. All sums past due and owing to Ryerson shall bear interest at the rate
of the lesser of one and one-half percent (1.5%) per month or the maximum rate permitted by applicable
law from the invoice date until paid in full. In the event Ryerson refers any unpaid past due balance to
an outside collection agency and/or attorney for collection, Buyer agrees to pay Ryerson’s reasonable
attorneys’ fees and all other costs of collection. For collection purposes only, the Buyer hereby agrees to
exclusive venue in either Minnesota or Arkansas. All payments made by Buyer to Ryerson shall be applied
in the following priority: (a) first to the amounts, if any, due to Ryerson for attorneys’ fees and court
costs, (b) second to the amounts, if any, due to Ryerson in the event of Buyer’s default, (c) third to the
amount, if any, of interest due to Ryerson as a result of Buyer’s late payment and (d) finally to the balance
of the purchase price due to Ryerson for the goods. To secure Buyer’s full and prompt payment of the
purchase price for the goods, Buyer hereby grants to Ryerson a first priority, purchase money security
interest in and to the goods and all products and proceeds therefrom. Buyer authorizes Ryerson to file a
UCC financing statement to perfect this security interest at any time. In addition, Ryerson will accept
credit card payments. In such instances, Buyer expressly agrees that Ryerson is permitted to invoice and
charge Buyer’s credit card (i) the applicable fees, applicable taxes and any other charges reflected on an
invoice plus except where prohibited by law (ii) any other fees Ryerson incurs in connection with
acceptance of credit cards payment on the applicable invoice including, without limitation, any surcharge
on the full amount of the invoice in an amount not to exceed Ryerson’s cost of acceptance. Ryerson will
provide Buyer with an invoice reflecting payment of (i) and (ii) above. Such fees, taxes and related
charges will be billed to the credit card that Buyer provides at the time a fee or charge is due and payable.
Buyer agrees to allow Ryerson, or its affiliates or services providers, to process and store Buyer’s
payment information.`,
				],
			},
			{
				title: "DELIVERIES",
				body: [
					`Any shipping schedule is approximate. Ryerson shall not be liable for any delay in
delivery or failure to deliver caused for any reason in whole or in part beyond our reasonable control.
Should shortages in Ryerson’s supply occur for any reason, Ryerson may allocate the material in such
manner and amount as Ryerson may determine. Acceptance by Buyer of any goods shall constitute a
waiver by Buyer of any claim for damages on account of any delay in delivery of such goods.`,
				],
			},
			{
				title: "SHIPMENTS",
				body: [
					`Unless otherwise expressly stated, shipment may be by carrier or other means
selected by Ryerson. Buyer assumes all risk of loss in transit.`,
				],
			},
			{
				title: "NUCLEAR APPLICATION EXCLUSION",
				body: [
					`Buyer represents and warrants that Buyer will not use, cause
to be used or make available for use the goods described herein in any nuclear application including, but
not limited to, use in connection with any nuclear reactor, any nuclear power generating system or any
nuclear waste (or spent fuel) disposal project. Any subsequent nuclear application of the goods is wholly
unauthorized and shall be deemed to be unknown to, unforeseeable to and unintended by Ryerson.`,
				],
			},
			{
				title: "SUSPENSION OF PERFORMANCE",
				body: [
					`If in Ryerson’s judgment reasonable doubt exists as to Buyer’s
financial responsibility, or if Buyer is past due in payment of any amount owing to Ryerson, Ryerson
reserves the right, without liability and without prejudice to any other remedies, to suspend
performance, decline to ship or to stop any material in transit, until Ryerson receives payment of all
amounts owing to Ryerson, whether or not due, or adequate assurance of such payment.`,
				],
			},
			{
				title: "WARRANTY",
				body: [
					`Ryerson warrants only that the goods will conform to their description as stated in
the invoice, subject to tolerances and variations described in the following
paragraph. <strong>RYERSON MAKES NO OTHER WARRANTY, EXPRESS OR IMPLIED, STATUTORY OR OTHER,
AND SPECIFICALLY DISCLAIMS AND EXCLUDES ANY AND ALL WARRANTIES OF MERCHANTABILITY AND
FITNESS FOR A PARTICULAR PURPOSE AND ANY WARRANTIES ARISING FROM COURSE OF DEALING,
USAGE OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, RYERSON MAKES NO WARRANTY
OF ANY KIND THAT THE GOODS WILL MEET BUYER’S OR ANY OTHER PERSON’S REQUIREMENTS.</strong> Any
material test reports, mill test reports or mill or material certifications with respect to the goods are
provided by the mills.`,
				],
			},
			{
				title: "TOLERANCES AND VARIATIONS",
				body: [
					`All goods shall be subject to tolerances and variations consistent
with usual trade practices regarding dimensions, straightness, section, composition and mechanical
properties and normal variations in surface and internal conditions and quality and shall also be subject
to deviations from tolerances and variations consistent with practical testing and inspection methods.`,
				],
			},
			{
				title: "INSPECTION",
				body: [
					`Materials must be examined by Buyer immediately upon receipt to determine
correctness of shipment as to quantity, size, length, and condition. Buyer must not cut, fabricate or
otherwise use material without such examination.`,
				],
			},
			{
				title: "CLAIMS",
				body: [
					`If, upon inspection by Buyer as provided above, any goods received by Buyer are
nonconforming, damaged, visibly defective or if the quantities received by Buyer do not agree with the
quantities indicated on the shipping documents, Buyer must notate that on the delivery receipt. If goods
are found to be nonconforming or damaged when unpacking and if Buyer intends to assert any claim
against Ryerson on this account, Buyer shall, within 30 days after receipt of such goods, furnish Ryerson
detailed written information of such damage, nonconformance, defect or shortage. Buyer’s failure to so
inform Ryerson or Buyer’s use of the material shall be Buyer’s acknowledgment that Ryerson has
satisfactorily performed. Ryerson will not honor any claims for damages caused by Buyer’s improper
storage of the goods.`,
				],
			},
			{
				title: "LIMITATION OF LIABILITY",
				body: [
					`Buyer’s exclusive remedy, and Ryerson only liability arising out of this
contract, shall be replacement or repair of the goods, or credit of the purchase price paid by Buyer,
whichever such remedy Ryerson shall select. If Ryerson elects to repay the purchase price, Buyer must
return the goods to Ryerson immediately. Except for nonpayment by Buyer, any action arising out of this
contract must be commenced within one year from the date of delivery. <strong>In no event will Ryerson be
liable for incidental or consequential damages, indirect damages, punitive damages, or damages for
loss of revenue or profit, business interruption, or business opportunity.<strong>`,
				],
			},
			{
				title: "TOOLS, DIES AND FIXTURES",
				body: [
					`Unless otherwise expressly provided, any tools, dies or fixtures which
may be developed for use in the production of the goods covered shall be owned by Ryerson or any
producing mill, as Ryerson may elect, even though Buyer is charged in whole or in part for the cost of
such tools, dies and fixtures.`,
				],
			},
			{
				title: "FORCE MAJEURE",
				body: [
					`Ryerson shall be excused for any failure or delay in the performance of any of its
obligations to Buyer if such failure or delay is due to a strike, work stoppage, labor dispute, material
shortage, utility outage, delay in transportation, fire, flood, earthquake, severe weather, act of God,
pandemic, accident, trade sanction, embargo, act of war, act of terror, condition caused by national
emergency, new or changed law, production schedules of the producing mill, unavailability of materials,
causes which abnormally increase the cost of performance, or any act or cause beyond the reasonable
control of Ryerson, and whether affecting Ryerson or its agents, subcontractors, dealers or suppliers, for
as long as such circumstances prevail. In such event, Ryerson will as soon as practicable notify the Buyer
of any actual or anticipated failure or delay.`,
				],
			},
			{
				title: "PATENT INFRINGEMENT",
				body: [
					`If any of the goods are to be furnished to Buyer’s specifications, Buyer
agrees to indemnify Ryerson and its successors and assigns, against all liabilities and expenses resulting
from any claim of infringement of any patent or other propriety right in connection with the production
of such goods.`,
				],
			},
			{
				title: "GOVERNMENT CONTRACTS",
				body: [
					`Unless otherwise expressly notified in writing, Buyer’s order has not
been placed under a prime contract with the U.S. Government or a subcontract thereunder and none of
the clauses contained in the government procurement regulations are incorporated herein. If Buyer’s
Order is subject to a U.S. Government contract or subcontract and is approved, all products sold are
Commercial Products within the definitions of FAR 2.101 (48 C.F.R. § 2.101), and are subject to FAR
52.212-4(a), (c), (e), (f), (j), (k), (l) (v), and Alt I, when applicable (all other provisions are excluded and
deemed self-deleting), which shall control. Buyer may rely on Ryerson’s SAM.gov representations and
certifications.`,
				],
			},
			{
				title: "COMPLIANCE WITH LAWS",
				body: [
					`Ryerson agrees that its sale of the goods will comply with all applicable
federal, state and local laws and regulations.`,
				],
			},
			{
				title: "CANCELLATION",
				body: [
					`A contract may be cancelled or modified only by written agreement between the
parties. Buyer’s insistence upon cancelling or suspending fabrication or shipment, or Buyer’s failure to
furnish specifications when required, may be treated by Ryerson as a breach of contract by Buyer, and
Ryerson may cancel any unshipped balance without prejudice to any other remedies Ryerson may have.`,
				],
			},
			{
				title: "SET-OFF",
				body: [
					`Buyer authorizes Ryerson to apply toward payment of any monies that become due
Ryerson hereunder any sums which may now or hereafter be owed to Buyer by Ryerson or by any
subsidiary or affiliated company of Ryerson.`,
				],
			},
			{
				title: "GOVERNING LAW",
				body: [
					`This contract and all other agreements, including all credit card commerce, with
respect to any and all sales by Ryerson shall be governed by and interpreted in accordance with Illinois
law, and the state or federal courts located in Cook County, Illinois, shall have exclusive jurisdiction with
respect to any disputes related hereto or thereto, which jurisdiction may be waived at Ryerson’s sole
discretion.`,
				],
			},
			{
				title: "ENTIRE AGREEMENT",
				body: [
					`The terms set forth herein constitute the sole terms and conditions upon
which Ryerson offers goods for sale. No other terms, conditions, or understandings, whether oral or
written shall be binding upon Ryerson, unless hereafter made in writing and signed by an authorized
representative of Ryerson. Ryerson hereby specifically objects to any different or additional terms that
may be contained in Buyer’s purchase order.`,
				],
			},
		],
	},
	bottom: [
		`STEEL, ALUMINUM, NICKEL, BRASS AND COPPER`,
		`THE ABOVE PRODUCTS, IN THEIR NATURAL STATE, DO NOT PRESENT AN INHALATION, INGESTION OR CONTACT HEALTH HAZARD; HOWEVER, PROCESSING OPERATIONS (INVOLVING THESE
MATERIALS) SUCH AS WELDING, BURNING, SAWING, BRAZING, GRINDING AND MACHINING MAY PRESENT A RESPIRATORY, SKIN OR EYE HAZARD. SEE MATERIAL SAFETY DATA SHEETS FOR
FURTHER INFORMATION.`,
		`Rev. 2/2025`,
	],
};

export const USContent: PrivacyPolicyBodyContent = {
	downloadLink:
		"/static-assets/documents/pdfs/en/policies-terms-conditions/TermsAndConditionsOfSale.pdf",
	downloadLabel: "Download Ryerson's U.S. Conditions and Terms of Sale",
	top: [
		`The following terms of sale shall be applicable to all sales made by
			RYERSON, as a d/b/a of Joseph T. Ryerson & Son, Inc., and its
			subsidiaries (“Ryerson”) except where it is otherwise expressly agreed in
			writing. Acceptance of delivery of any or all goods ordered by a buyer (“Buyer”)
			shall constitute assent by the Buyer to these terms of sale collectively.`,
	],
	list: {
		type: "ordered",
		listItems: [
			{
				title: "PRICES",
				body: [
					`The prices quoted may be changed by Ryerson without notice to reflect
						Ryerson's prices at time of shipment, and any increase in transportation, labor or other costs.
						If a delivered price has been quoted, any charges at destination for spotting, switching,
						handling, storage and other accessorial services and demurrage shall be borne by Buyer, and
						any increase or decrease in transportation charges shall be added to or subtracted from the
						quoted price. Ryerson reserves the right to correct any errors in specifications or prices.`,
				],
			},
			{
				title: "SURCHARGES AND TAXES",
				body: [
					`Any and all surcharges imposed by Ryerson's suppliers at the time of shipment shall be paid by Buyer.
						All prices quoted by Ryerson are exclusive of all taxes. In addition to the purchase price, 
						Buyer shall pay or reimburse Ryerson the amount of all sales, use and ad valorem taxes, excises, duties, 
						tariffs, and/or other governmental charges, under any existing or future law, that Ryerson may be required 
						to pay with respect to the sale, purchase, delivery, storage, processing, use, consumption or transportation 
						of any of the goods or services covered. The foregoing shall not apply to any taxes the payment or collection 
						of which by Ryerson is excused by reason of delivery to Ryerson of valid tax exemption certificates.`,
				],
			},
			{
				title: "QUANTITIES",
				body: [
					`If an item is to be shipped from a producing mill, the producing mill may
						reserve the privilege of shipping over or under the ordered quantity in accordance with
						established percentages (as shown in their price books), which will constitute acceptable and
						complete shipment of the material specified.`,
				],
			},
			{
				title: "SALE ON CREDIT; TERMS OF PAYMENT",
				body: [
					`Ryerson reserves the right in its sole discretion to approve, conditionally approve or disapprove any request by Buyer for credit. The amount
						of credit Ryerson extends to Buyer will be determined by Ryerson in its discretion and may vary from time to time. Upon Ryerson's request from time to time, Buyer will provide 
						Ryerson with current financial information. Buyer grants permission to Ryerson to obtain independent credit reports and other information from Buyer's trade references and banks
						and authorizes credit references and banks to release information that may be used to determine creditworthiness, both now and in the future. Buyer represents and warrants that 
						any financial information provided to Ryerson will be true and correct in all material respects and shall fairly and accurately present the financial condition of Buyer as of the date of such 
						financial statements. Buyer shall notify Ryerson, in writing, of any error in any invoice within ten (10) days after the Buyer's receipt of such invoice, and, if no such notice from Buyer is 
						received by Ryerson, the invoice shall be deemed to be correct and payable as delivered to Buyer. Unless otherwise expressly provided by Ryerson in writing, Ryerson's terms will be 
						net thirty (30) days from the date of invoice. All sums past due and owing to Ryerson shall bear interest at the rate of the lesser of one and one-half percent (1.5%) per month or the maximum rate
						permitted by applicable law from the invoice date until paid in full. In the event Ryerson refers any unpaid past due balance to an outside collection agency and/or attorney for collection, Buyer agrees to pay
						Ryerson's reasonable attorneys' fees and all other costs of collection. For collection purposes only, the Buyer hereby agrees to exclusive venue in either Minnesota or Arkansas.
						All payments made by Buyer to Ryerson shall be applied in the following priority: (a) first to the amounts, if any, due to Ryerson for attorneys' fees
						and court costs, (b) second to the amounts, if any, due to Ryerson in the event of Buyer's default, (c) third to the amount, if any, of interest due to Ryerson
						as a result of Buyer's late payment and (d) finally to the balance of the purchase price due to Ryerson for the goods. To secure Buyer's full and prompt payment of
						the purchase price for the goods, Buyer hereby grants to Ryerson a first priority, purchase money security interest in and to the goods and all products and proceeds
						there from. Buyer authorizes Ryerson to file a UCC financing statement to perfect this security interest at any time. `,
				],
			},
			{
				title: "DELIVERIES",
				body: [
					`Any shipping schedule is approximate. Ryerson shall not be liable for any delay in delivery or
						failure to deliver caused for any reason in whole or in part beyond our reasonable control.
						Should shortages in Ryerson's supply occur for any reason, Ryerson may allocate the material in such manner and amount
						as Ryerson may determine. Acceptance by Buyer of any goods shall constitute a waiver by Buyer of any claim for damages on account of
						any delay in delivery of such goods.`,
				],
			},
			{
				title: "SHIPMENTS",
				body: [
					`Unless otherwise expressly stated, shipment may be by carrier or other means selected by Ryerson. Buyer assumes all risk of loss in transit.`,
				],
			},
			{
				title: "NUCLEAR APPLICATION EXCLUSION",
				body: [
					`Buyer represents and warrants that Buyer will not use, cause to be used or make available for use the goods described herein in any nuclear
						application including, but not limited to, use in connection with any nuclear reactor, any nuclear power generating system or any nuclear waste (or spent fuel)
						disposal project. Any subsequent nuclear application of the goods is wholly unauthorized and shall be deemed to be unknown to, unforeseeable to and unintended by Ryerson.`,
				],
			},
			{
				title: "SUSPENSION OF PERFORMANCE",
				body: [
					`If in Ryerson's judgment reasonable doubt exists as to Buyer's financial responsibility, or if Buyer is past due in payment of any amount owing to Ryerson,
						Ryerson reserves the right, without liability and without prejudice to any other remedies, to suspend performance, decline to ship or to stop any material in transit,
						until Ryerson receives payment of all amounts owing to Ryerson, whether or not due, or adequate assurance of such payment. `,
				],
			},
			{
				title: "WARRANTY",
				body: [
					`Ryerson warrants only that the goods will conform to their description as stated in the invoice, subject to tolerances and variations described in the following paragraph.
						RYERSON MAKES NO OTHER WARRANTY, EXPRESS OR IMPLIED, STATUTORY OR OTHER, AND SPECIFICALLY DISCLAIMS AND EXCLUDES ANY AND ALL WARRANTIES OF MERCHANTABILITY AND
						FITNESS FOR A PARTICULAR PURPOSE AND ANY WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, RYERSON MAKES NO WARRANTY OF ANY KIND
						THAT THE GOODS WILL MEET BUYER'S OR ANY OTHER PERSON'S REQUIREMENTS. Any material test reports, mill test reports or mill or material certifications with respect to the goods are
						provided by the mills.`,
				],
			},
			{
				title: "TOLERANCES AND VARIATIONS",
				body: [
					`All goods shall be subject to tolerances and variations consistent with usual trade practices regarding dimensions, straightness, section, composition and mechanical properties and normal variations
						in surface and internal conditions and quality and shall also be subject to deviations from tolerances and variations consistent with practical testing and inspection methods.`,
				],
			},
			{
				title: "INSPECTION",
				body: [
					` Materials must be examined by Buyer immediately upon receipt to determine correctness of shipment as to quantity, size, length, and condition.
						Buyer must not cut, fabricate or otherwise use material without such examination.`,
				],
			},
			{
				title: "CLAIMS",
				body: [
					`If, upon inspection by Buyer as provided above, any goods received by Buyer are nonconforming, damaged, visibly defective or if the quantities
						received by Buyer do not agree with the quantities indicated on the shipping documents, Buyer must notate that on the delivery receipt.
						If goods are found to be nonconforming or damaged when unpacking and if Buyer intends to assert any claim against Ryerson on this account, Buyer shall,
						within 30 days after receipt of such goods, furnish Ryerson detailed written information of such damage, nonconformance, defect or shortage. Buyer's failure to so
						inform Ryerson or Buyer's use of the material shall be Buyer's acknowledgment that Ryerson has satisfactorily performed. Ryerson will not honor any claims for damages
						caused by Buyer's improper storage of the goods.`,
				],
			},
			{
				title: "LIMITATION OF LIABILITY",
				body: [
					` Buyer's exclusive remedy, and Ryerson only liability arising out of this contract, shall be replacement or repair of the goods,
						or credit of the purchase price paid by Buyer, whichever such remedy Ryerson shall select. If Ryerson elects to repay the purchase price,
						Buyer must return the goods to Ryerson immediately. Except for nonpayment by Buyer, any action arising out of this contract must be commenced within one year
						from the date of delivery. In no event will Ryerson be liable for incidental or consequential damages, indirect damages, punitive damages, or damages for loss of
						revenue or profit, business interruption, or business opportunity.`,
				],
			},
			{
				title: "TOOLS, DIES AND FIXTURES",
				body: [
					`Unless otherwise expressly provided, any tools, dies or fixtures which may be developed for use in the production of the goods covered shall be owned by Ryerson
						or any producing mill, as Ryerson may elect, even though Buyer is charged in whole or in part for the cost of such tools, dies and fixtures. `,
				],
			},
			{
				title: "FORCE MAJEURE",
				body: [
					`Ryerson shall be excused for any failure or delay in the performance of any of its obligations to Buyer if such failure or delay is due to a strike,
						work stoppage, labor dispute, material shortage, utility outage, delay in transportation, fire, flood, earthquake, severe weather, act of God, pandemic, accident,
						trade sanction, embargo, act of war, act of terror, condition caused by national emergency, new or changed law, production schedules of the producing mill, unavailability of materials,
						causes which abnormally increase the cost of performance, or any act or cause beyond the reasonable control of Ryerson, and whether affecting Ryerson or its agents, subcontractors, dealers or suppliers, for as long as such circumstances prevail.
						In such event, Ryerson will as soon as practicable notify the Buyer of any actual or anticipated failure or delay.`,
				],
			},
			{
				title: "PATENT INFRINGEMENT",
				body: [
					`If any of the goods are to be furnished to Buyer's specifications, Buyer agrees to indemnify Ryerson and its successors and assigns,
						against all liabilities and expenses resulting from any claim of infringement of any patent or other propriety right in connection with the production of such goods.`,
				],
			},
			{
				title: "GOVERNMENT CONTRACTS",
				body: [
					`Unless otherwise expressly notified in writing, Buyer's order has not been placed under a prime contract with the U.S. Government or a subcontract
						thereunder and none of the clauses contained in the government procurement regulations are incorporated herein. `,
				],
			},
			{
				title: "COMPLIANCE WITH LAWS",
				body: [
					`Ryerson agrees that its sale of the goods will comply with all applicable federal, state and local laws and regulations.`,
				],
			},
			{
				title: "CANCELLATION",
				body: [
					`A contract may be cancelled or modified only by written agreement between the parties.
						Buyer's insistence upon cancelling or suspending fabrication or shipment, or Buyer's failure to furnish specifications when required,
						may be treated by Ryerson as a breach of contract by Buyer, and Ryerson may cancel any unshipped balance without prejudice to any other remedies Ryerson may have.`,
				],
			},
			{
				title: "SET-OFF",
				body: [
					`Buyer authorizes Ryerson to apply toward payment of any monies that become due Ryerson hereunder any sums which may now or hereafter
						be owed to Buyer by Ryerson or by any subsidiary or affiliated company of Ryerson.`,
				],
			},
			{
				title: "GOVERNING LAW",
				body: [
					`This contract and all other agreements with respect to any and all sales by Ryerson shall be governed by Illinois law, and the state or
						federal courts located in Cook County, Illinois, shall have exclusive jurisdiction with respect to any disputes related hereto or thereto, which jurisdiction may be waived at Ryerson's sole discretion.`,
				],
			},
			{
				title: "ENTIRE AGREEMENT",
				body: [
					` The terms set forth herein constitute the sole terms and conditions upon which Ryerson offers goods for sale. No other terms, conditions, or understandings,
						whether oral or written shall be binding upon Ryerson, unless hereafter made in writing and signed by an authorized representative of Ryerson.
						Ryerson hereby specifically objects to any different or additional terms that may be contained in Buyer's purchase order.`,

					`STEEL, ALUMINUM, NICKEL, BRASS AND COPPER`,
					`THE ABOVE PRODUCTS, IN THEIR NATURAL STATE, DO NOT PRESENT AN INHALATION, INGESTION OR CONTACT HEALTH HAZARD;
						HOWEVER, PROCESSING OPERATIONS (INVOLVING THESE MATERIALS) SUCH AS WELDING, BURNING, SAWING, BRAZING, GRINDING AND MACHINING
						MAY PRESENT A RESPIRATORY, SKIN OR EYE HAZARD. SEE MATERIAL SAFETY DATA SHEETS FOR FURTHER INFORMATION.`,
				],
			},
		],
	},
};

export const CanadaContent: PrivacyPolicyBodyContent = {
	downloadLink: [
		"/static-assets/documents/pdfs/en/policies-terms-conditions/TermsAndConditionsCanadaQuebec.pdf",
		"/static-assets/documents/pdfs/en/policies-terms-conditions/TermsAndConditionsCanadaOntario.pdf",
	],
	downloadLabel: [
		"Download Ryerson's Canada Terms and Conditions for use in Quebec",
		"Download Ryerson's Canada Terms and Conditions for use in Ontario",
	],
	top: [`TERMS AND CONDITIONS OF SALE`, `RYERSON CANADA INC.`],
	list: {
		type: "ordered",
		listItems: [
			{
				title: "AGREEMENT",
				body: [
					`Whenever used herein, the term “Agreement” means these Terms and Conditions of Sale as may be
						amended at the sole discretion of Ryerson Canada Inc. and its successors and assigns (“Ryerson”) at any time and from time
						to time, together with the Credit Application and Agreement (if applicable). The term “goods” includes all goods or
						component parts sold, whether manufactured by Ryerson or another supplier.`,
				],
			},
			{
				title: "PAYMENTS",
				body: [
					`Unless otherwise agreed by Ryerson in writing, all amounts payable hereunder shall be due to Ryerson within
						thirty (30) days of invoice date, time being of the essence. Late payments shall bear interest at the rate of 18% per annum or
						the highest rate permitted by law, whichever is less. All prices shown are net, and in addition to the price of goods, Customer
						shall pay all expenses including taxes, insurance, freight, carriage, and warehousing. All amounts shall be paid by the
						Customer to Ryerson without any setoff, deduction or recoupment`,
				],
			},
			{
				title: "TAXES AND PRICING",
				body: [
					`All prices quoted are subject to change without notice and are exclusive of taxes. Customer shall
						pay all taxes resulting from transactions, including, without limitation, occupation, property, excise, sales, or use taxes, but
						excluding any taxes based on the income of Ryerson. The purchase price shall be subject to increase based on Ryerson’s
						established price at the date of actual shipment if shipment is delayed thirty (30) days or more beyond the scheduled shipment
						date and such delay is caused in whole or in part by circumstances beyond the reasonable control of Ryerson as provided in
						paragraph 11.`,
				],
			},
			{
				title: "SHIPMENT",
				body: [
					`. (a) Scheduled shipment date is an estimate only. On or after the scheduled shipment date, Customer shall accept
							shipment upon notification by Ryerson; or, if Customer refuses shipment, then Ryerson is authorized at its option to: (i) have
							the goods transported and warehoused at Customer's expense and risk, which shall constitute shipment to Customer, in which
							event Ryerson may declare as immediately due all amounts owing upon shipment including the additional cost of such
							transportation and warehousing; (ii) declare the monthly installment payments to commence thirty (30) days from the date of
							such transportation and warehousing if any amounts are financed by Ryerson; or, (iii) defer shipment. (b) Ryerson may make
							partial shipments hereunder and may invoice for each such partial shipment separately. Each partial Shipment will be deemed
							to be a separate sale but a delay in delivery of any partial shipment will not relieve Customer of its obligation to accept
							delivery of any remaining shipments. (c) Ryerson's shipping weights will govern for each shipment or partial shipment.
							Should Customer dispute the shipping weight of any shipment or partial shipment, Customer will notify Ryerson in writing
							prior to the time of deemed acceptance specified in paragraph 7 of the reasons for such dispute and provide to Ryerson all
							necessary documentation to substantiate any alleged discrepancy.`,
				],
			},
			{
				title: "SHIPPING TOLERANCES",
				body: [
					` The goods sold are subject either to Ryerson's published shipping tolerances in effect on the
						date of order, or if Ryerson has no such tolerances, then to applicable industry shipping tolerances for the goods in question.`,
				],
			},
			{
				title: "TITLE/RISK OF LOSS/INSURANCE",
				body: [
					`Title in the goods shall remain with Ryerson until such goods have been paid for in
						full. However, such goods shall be entirely at Customer's risk from the time they are placed in the possession of the carrier
						for shipment to Customer. Customer shall ensure that the goods are insured against “all risks” from the time the goods are
						placed in the possession of the carrier for shipment to Customer, and continuously thereafter until all amounts due to Ryerson
						are paid in full. Such insurance shall be for no less than the total amount owing to Ryerson with loss first payable to Ryerson.
						Purchaser shall indemnify Ryerson from all loss arising out of any claims, suits and demands by reason of the retention of
						title to the goods by Ryerson while the goods are at the Customer's risk.`,
				],
			},
			{
				title: "ACCEPTANCE OF GOODS",
				body: [
					`Customer shall inspect or test all goods upon receipt. Customer shall be deemed to have
						effected final acceptance of the goods at the earliest of: (a) the fifteenth (15th) day after the date of shipment, unless written
						notice is received by Ryerson before such day; or, (b) the date when the goods are used or otherwise placed in commercial
						operation.`,
				],
			},
			{
				title: "WARRANTY",
				body: [
					`(a) Ryerson warrants that title to the goods sold shall be free from any encumbrance, and that the goods will
						conform to the description contained on Ryerson's invoice. (b) SUBJECT TO APPLICABLE LAW, RYERSON MAKES
						NO OTHER REPRESENTATIONS OR WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, EXCEPT AS
						PROVIDED IN WRITING SIGNED BY AN AUTHORIZED OFFICER OF RYERSON, AND SPECIFICALLY
						EXCLUDES ALL LEGAL OR IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY WARRANTY
						AS TO MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. (c) Customer is responsible for the
						designation and selection of product sold by Ryerson. Customer shall hold Ryerson harmless and indemnify and defend
						Ryerson (including its affiliates, assigns, directors, officers, employees, agents and representatives) for any claims arising out
						of or relating to the design, specification, or use of product(s) sold by Ryerson to Customer.`,
				],
			},
			{
				title: "CUSTOMER'S REMEDY",
				body: [
					`Ryerson's sole responsibility and liability and Customer's exclusive remedy under this
						Agreement shall be limited to the repair or replacement of goods (f.o.b. Ryerson's shipping point), or, at Ryerson's option,
						return of the goods and refund of moneys paid thereon, without interest, provided Customer is not in default hereunder.
						SUBJECT TO APPLICABLE LAW, IN NO EVENT SHALL RYERSON BE LIABLE FOR INDIRECT, INCIDENTAL,
						CONSEQUENTIAL, OR EXEMPLARY DAMAGES, OR LEGAL FEES. Ryerson's obligation hereunder is subject to
						receipt of written notice of defect (containing detailed particulars of the alleged defect) from Customer prior to the time of
						deemed acceptance specified in paragraph 7.`,
				],
			},
			{
				title: "RETURNS",
				body: [
					`Returned goods will be accepted only if Ryerson has given prior written consent. A charge for handling,
						inspection, restocking and invoicing of up to 25% of the sale price of the returned goods shall be assessed against the
						Customer. All returns allowed must be shipped at Customer's expense and must be in excellent resale condition. Material cut
						to Customer's specifications is not returnable.`,
				],
			},
			{
				title: "DELAY OR NONPERFORMANCE",
				body: [
					` Ryerson shall not be liable for failure or delay in performance hereunder due in whole
						or in part to strikes, work stoppages, fires, acts of terrorism, accidents, wars, rebellions, civil commotion, public strife, acts of
						any government whether legal or otherwise, acts of public enemies, force majeure, inability to secure or delay in securing
						transportation, inability to obtain or delays in obtaining goods, materials, or qualified labor, or any other causes beyond
						Ryerson's reasonable control, including without limitation delays or inability to obtain product because of the actions of
						Ryerson's suppliers.`,
				],
			},
			{
				title: "DEFAULT",
				body: [
					`Customer shall be in default under this Agreement if any of the following occurs: (a) Customer refuses to accept
						shipment or fails to make any payment when due; (b) there is a breach of any covenant or agreement by Customer; (c)
						Customer dies, ceases to exist, becomes insolvent or the subject of bankruptcy, insolvency, or liquidation proceedings; (d)
						Customer attempts to assign its rights and obligations under this Agreement without the prior written consent of Ryerson; (e)
						any representation, warranty, condition, or certification of Customer or any information provided by Customer in or pursuant
						to this Agreement is false in any material respect when made; (f) Ryerson, in good faith, considers that payment or
						performance of any obligation of Customer to Ryerson arising out of this Agreement or any other agreement between
						Customer and Ryerson or any affiliate of Ryerson, including any modifications, renewals or extensions thereof, is impaired.`,
				],
			},
			{
				title: "REMEDIES UPON DEFAULT",
				body: [
					` In the event of Customer's default: (a) Ryerson may, at its option, (i) charge Customer
						interest at the rate of 18% per annum or the highest rate permitted by law, and/or (ii) take immediate possession of the goods
						and remove same without notice and without legal proceedings, and/or declare all unpaid amounts immediately due and
						payable and/or suspend shipments to Customer; (b) Waiver by Ryerson of any breach or default shall not constitute a waiver
						of any subsequent breach or default; (c) Ryerson shall be entitled to set off any amount owed by Customer or any of
						Customer's related entities against any amount payable by Ryerson in connection with any unpaid monies due to Customer;
						(d) Ryerson at its discretion and option shall be entitled to retain all money paid by Customer on account as liquidated
						damages; and, (e) Ryerson shall have all the rights and remedies provided by law in addition to all other rights as established
						herein, which rights and remedies shall be cumulative`,
				],
			},
			{
				title: "EQUIPMENT",
				body: [
					`(a) Any equipment, including jigs, dies, and tools, which Ryerson acquires for use in the production of goods
						for Customer, is the property of Ryerson and will remain in its possession and control, and Ryerson may at its sole discretion
						modify any such equipment. (b) Any material or equipment furnished by Customer to Ryerson will be carefully handled and
						stored by Ryerson while in Ryerson's possession. When for eighteen (18) consecutive months no orders acceptable to
						Ryerson are received from Customer for goods to be made from any such equipment or materials, Ryerson may by written
						notice request that Customer make disposition thereof at Customer's expense. If Customer fails to comply with such notice
						within thirty (30) days of receipt of such written notice, Ryerson may make such use or disposition of said materials or
						equipment as it desires, with no liability or obligation whatsoever to Customer, and Customer hereby agrees to hold Ryerson
						harmless and indemnify and defend Ryerson (including its affiliates, assigns, directors, officers, employees, agents and
						representatives) against any claims by third parties arising out of or relating to such use or disposition of the said materials.`,
				],
			},
			{
				title: "CANCELLATION",
				body: [
					`Upon receipt of written notice from Customer, Ryerson shall cancel any orders as instructed, subject to
						Ryerson's (or its subcontractors') right to continue processing raw or finished material to the point at which processing can
						be halted with the least disruption and cost to Ryerson. Customer shall be responsible for all costs associated with the
						cancellation and completion of processing of material.`,
				],
			},
			{
				title: "ACCEPTANCE",
				body: [
					`This Agreement shall be binding on Customer by its purchase of goods from Ryerson and may only be
						modified in writing, signed by Ryerson, and each of the provisions of this Agreement shall remain in effect unless and until
						explicitly contradicted by the aforesaid writing. If Customer submits an order form with contrary terms or conditions, such
						order form shall be considered only as confirmation of the order and shall in no way amend, prevail over, supplement, or
						supersede any of the provisions of this Agreement. Customer waives any defense to the validity or enforceability of this
						Agreement arising from any electronic submission of it to Customer. Customer acknowledges and agrees that it has the
						ability to access each URL referenced in any quote or other document delivered to it by Ryerson.`,
				],
			},
			{
				title: "GENERAL",
				body: [
					`Ryerson may assign its rights and obligations under this Agreement. Customer may not assign its obligations
						under this Agreement to any person without Ryerson's prior written consent of Ryerson. If Customer changes its corporate
						status, both Customer and its successors continue to be bound by this Agreement, and Ryerson reserves its rights and
						remedies on default. No prior representation, affirmation, or agreement shall be enforceable unless set forth herein.`,
				],
			},
			{
				title: "NUCLEAR USE",
				body: [
					`The goods covered by this Agreement and sold by Ryerson are not intended for and have not been certified
						for application or fabrication in connection with the use or handling of nuclear material or the construction, operation or
						maintenance of a nuclear installation or facility ("Nuclear Use") and Ryerson gives no representation or warranty, express or
						implied, as to the suitability or safety of such goods for any Nuclear Use. If Customer intends to use these goods or permit
						any other person to use these goods for a Nuclear Use, Customer shall be solely responsible for satisfying itself as to their
						suitability and safety for such use and/or upgrading the goods to ensure such suitability and safety. Ryerson disclaims all
						liability for any nuclear or other damages, injury, loss of life, radioactive contamination or economic loss resulting from or 
						alleged to result from any Nuclear Use of the goods by Customer or any other person, and Customer shall hold Ryerson
						harmless from and indemnify and defend Ryerson (including its affiliates, successors, assigns, directors, officers, employees,
						agents and representatives) against any and all claims, losses, damages, costs, actions, causes of action, judgments, expenses
						and liabilities of every kind and nature whatsoever (including, without limitation, legal fees and costs and expenses of
						defense), whether in contract, tort or otherwise, which, either directly or indirectly, are, or are alleged to in any way be
						connected with, arise out of or result from such Nuclear Use.`,
				],
			},
			{
				title: "DISPUTE RESOLUTION/GOVERNING LAW",
				body: [
					` The validity, construction and enforcement of this Agreement are governed
						by the laws of the province of Ontario and the federal laws of Canada applicable therein. The Customer irrevocably submits
						to the non-exclusive jurisdiction of the courts of the province of Ontario. Reasonable legal fees and costs shall be awarded to
						the prevailing party in any litigation. Customer must institute any action against Ryerson within one (1) year after Customer’s
						claim arises, or, subject to applicable law, such claim shall be barred notwithstanding any statutory limitations to the
						contrary.`,
				],
			},
			{
				title: "SEVERABILITY",
				body: [
					`If, in any jurisdiction, any provision of this Agreement or its application to any party or circumstance is
						restricted, prohibited, or unenforceable, such provision shall, as to such jurisdiction, be ineffective only to the extent of such
						restriction, prohibition, or unenforceability, without invalidating the remaining provisions of this Agreement and without
						affecting the validity or enforceability of such provision in any other jurisdiction or without affecting its application to other
						party or circumstances.`,
				],
			},
			{
				title: "INTERNATIONAL",
				body: [
					`The United Nations Convention on Contracts for the International Sale of Goods shall not apply to this
						Agreement or any goods ordered or issued under this Agreement. Each party agrees to comply with all applicable corruption
						of public officials and antibribery laws and regulations.`,
				],
			},
			{
				title: "LANGUAGE",
				body: [
					`The parties to this Agreement confirm that it is their wish that this Agreement, as well as any other documents
						relating to this Agreement, have been and shall be drawn up in the English language only. Les signataires confirment leur
						volonté que la présente convention, de même que tous les documents s'y rattachant, soient rédigés en anglais seulement.`,
				],
			},
		],
	},
};

export const CanadaFrenchContent: PrivacyPolicyBodyContent = {
	downloadLink: [
		"/static-assets/documents/pdfs/fr/policies-terms-conditions/TermsAndConditionsCanadaQuebecFrench.pdf",
		"/static-assets/documents/pdfs/fr/policies-terms-conditions/TermsAndConditionsCanadaOntarioFrench.pdf",
	],
	downloadLabel: [
		"Télécharger les conditions générales de Ryerson Canada pour une utilisation au Québec",
		"Télécharger les conditions générales de Ryerson Canada pour une utilisation en Ontario",
	],
	top: [`MODALITÉS ET CONDITIONS DE VENTE`, `RYERSON CANADA INC.`],
	list: {
		type: "ordered",
		listItems: [
			{
				title: "CONVENTION",
				body: [
					` Lorsqu'il est utilisé dans les présentes, le terme « Convention » désigne les présentes modalités et conditions
						de vente, en leur version modifiée à la seule discrétion de Ryerson Canada Inc. et de ses successeurs et ayants droit
						(« Ryerson ») en tout temps et de temps à autre, ainsi que la Demande de crédit et la Convention (le cas échéant). Le terme
						« marchandises » inclut toutes les marchandises ou les composantes vendues, qu'elles soient fabriquées par Ryerson ou par un
						autre fournisseur. Le terme « Client » désigne le client qui achète des marchandises auprès de Ryerson aux termes de la présente
						Convention.`,
				],
			},
			{
				title: "PAIEMENTS",
				body: [
					`Sauf si Ryerson en convient autrement par écrit, toutes les sommes payables aux termes des présentes doivent
						être versées à Ryerson dans les trente (30) jours suivant la date de facturation, le délai étant de rigueur. Les paiements en
						souffrance portent intérêt au taux de 18 % par année. Tous les prix affichés sont nets et, en sus du prix des marchandises, le
						Client est tenu de payer tous les frais, y compris les taxes, les droits, les primes d'assurances et les frais d'expédition, de
						transport et d'entreposage. Le Client doit verser à Ryerson la totalité des sommes, sans compensation, déduction ou retenue.`,
				],
			},
			{
				title: "TAXES ET PRIX",
				body: [
					`Tous les prix affichés peuvent changer sans préavis et ne comprennent pas les taxes. Le Client doit acquitter
						l'ensemble des taxes et des droits rattachés aux opérations, y compris notamment les droits d'importation, les taxes sur
						l'occupation, les taxes foncières, la taxe d'accise, la taxe sur les biens et services, les taxes de vente ou les taxes d'utilisation,
						mais à l'exception des taxes et impôts fondés sur le revenu de Ryerson. Le prix d'achat peut être supérieur au prix établi par
						Ryerson au moment de l'expédition effective si l'expédition est retardée de trente (30) jours ou plus après la date d'expédition
						prévue, et que ce retard est causé, en totalité ou en partie, par des circonstances qui échappent au contrôle raisonnable de
						Ryerson, comme il est prévu au paragraphe 11.`,
				],
			},
			{
				title: "EXPÉDITION",
				body: [
					` a) La date d'expédition prévue est une estimation seulement. À compter de la date d'expédition prévue, le
						Client doit accepter l'expédition à la réception d'un avis de Ryerson; ou, si le Client refuse l'expédition, Ryerson a le droit de
						faire ce qui suit, à son gré : i) transporter et entreposer les marchandises aux frais et aux risques du Client, ce qui constitue une
						expédition au Client, auquel cas Ryerson peut déclarer que toutes les sommes payables à l'expédition, y compris les frais
						supplémentaires rattachés au transport et à l'entreposage, sont payables immédiatement; ii) déclarer que les versements
						mensuels commencent trente (30) jours à compter de la date d'un tel transport et entreposage si des sommes sont financées par
						Ryerson, ou iii) reporter l'expédition. b) Ryerson peut effectuer des expéditions partielles aux termes des présentes et peut
						facturer chacune de ces livraisons partielles de façon distincte. Chaque expédition partielle sera réputée constituer une vente
						distincte, mais un retard de livraison d'une expédition partielle ne libérera pas le Client de son obligation d'accepter la livraison
						des autres envois. c) Chaque envoi ou envoi partiel sera régi par les poids d'expédition de Ryerson. Le Client qui conteste le
						poids d'expédition d'une expédition ou d'une expédition partielle doit aviser Ryerson, par écrit et avant le moment de
						l'acceptation réputée indiquée au paragraphe 7, des motifs de sa contestation et fournir à Ryerson tous les documents
						nécessaires en appui à la différence alléguée.`,
				],
			},
			{
				title: "TOLÉRANCES D'EXPÉDITION",
				body: [
					`Les marchandises vendues sont assujetties aux tolérances d'expédition publiées par
						Ryerson en vigueur à la date de la commande ou, si Ryerson n'applique aucune tolérance de ce genre, aux tolérances
						d'expédition applicables dans l'industrie pour les marchandises en question.`,
				],
			},
			{
				title: "PROPRIÉTÉ/RISQUE DE PERTE/ASSURANCE",
				body: [
					` Ryerson conserve la propriété des marchandises jusqu'à ce que ces
						marchandises aient été payées en totalité. Toutefois, les risques rattachés à ces marchandises sont entièrement assumés par le
						Client dès le moment où elles se trouvent entre les mains de l'expéditeur aux fins de livraison au Client. Le Client doit s'assurer
						que les marchandises sont couvertes par une assurance « tous risques » à compter du moment où elles sont placées entre les
						mains de l'expéditeur aux fins de livraison au Client, et ce, continuellement par la suite jusqu'à ce que toutes les sommes
						payables à Ryerson soient intégralement acquittées. Cette assurance doit couvrir au minimum le montant total de la somme
						payable à Ryerson, la perte étant d'abord payable à Ryerson. Le Client indemnisera Ryerson à l'égard de toutes les pertes
						découlant de toute réclamation, poursuite ou demande en raison de la réserve de propriété des marchandises par Ryerson alors
						que les risques rattachés aux marchandises sont assumés par le Client.`,
				],
			},
			{
				title: "ACCEPTATION DES MARCHANDISES",
				body: [
					`Le Client doit inspecter ou tester toutes les marchandises dès leur réception. Le
						Client sera réputé avoir procédé à l'acceptation finale des marchandises à la date la plus rapprochée parmi les suivantes : a) le
						quinzième (15e) jour suivant la date d'expédition, sauf si Ryerson reçoit un avis écrit avant ce jour; ou b) la date à laquelle les
						marchandises sont utilisées ou sont autrement mises en exploitation commerciale.`,
				],
			},
			{
				title: "GARANTIE",
				body: [
					`a) Ryerson garantit que les titres de propriété des marchandises vendues sont libres de toutes charges
						(à l'exception de la réserve de propriété en faveur de Ryerson jusqu'à ce que le prix des marchandises ait été acquitté
						intégralement), et que les marchandises seront conformes à la description qui figure sur la facture de Ryerson. b) SOUS
						RÉSERVE DES LOIS APPLICABLES, RYERSON NE FAIT AUCUNE AUTRE DÉCLARATION ET NE DONNE
						AUCUNE AUTRE GARANTIE NI NE POSE DE CONDITION, EXPRESSE OU IMPLICITE, QUE CELLES QUI SONT
						DÉCRITES DANS UN DOCUMENT ÉCRIT SIGNÉ PAR UN DIRIGEANT AUTORISÉ DE RYERSON, ET EXCLUT
						EXPRESSÉMENT TOUTE GARANTIE LÉGALE OU IMPLICITE, Y COMPRIS, MAIS NON DE FAÇON LIMITATIVE,
						TOUTE GARANTIE QUANT À LA QUALITÉ MARCHANDE OU À L'ADÉQUATION À UN USAGE PARTICULIER.
						c) Le Client est responsable de la désignation et de la sélection de produits vendus par Ryerson. Le Client doit défendre,
						indemniser et tenir à couvert Ryerson (y compris les membres de son groupe et ses cessionnaires, administrateurs, dirigeants,
						employés, mandataires et représentants) à l'égard de l'ensemble des réclamations qui découlent de la conception,
						des spécifications ou de l'utilisation du ou des produits que Ryerson vend au Client, ou qui s'y rapportent.`,
				],
			},
			{
				title: "RECOURS DU CLIENT",
				body: [
					`La seule responsabilité et obligation de Ryerson et l'unique recours du Client aux termes de la
						présente Convention se limitent à la réparation ou au remplacement des marchandises (FOB au point d'expédition de Ryerson)
						ou, au gré de Ryerson, au retour des marchandises et au remboursement des sommes payées pour celles-ci, sans intérêt, pourvu
						que le Client ne se trouve pas en situation de défaut aux termes des présentes. SOUS RÉSERVE DES LOIS APPLICABLES,
						RYERSON NE PEUT EN AUCUN CAS ÊTRE TENUE RESPONSABLE DES DOMMAGES-INTÉRÊTS INDIRECTS,
						ACCESSOIRES, CONSÉCUTIFS OU EXEMPLAIRES, DE LA PERTE DE PROFITS OU DES FRAIS JURIDIQUES.
						L'obligation de Ryerson aux termes des présentes est conditionnelle à la réception d'un avis de défaut écrit (qui détaille le
						défaut allégué) remis par le Client avant l'acceptation réputée indiquée au paragraphe 7.`,
				],
			},
			{
				title: "RETOURS",
				body: [
					` Les marchandises retournées seront acceptées uniquement si Ryerson a donné son consentement écrit au préalable.
						Des frais de manutention, d'inspection, de remise en stock et de facturation, pouvant aller jusqu'à 25 % du prix de vente des
						marchandises retournées, seront imposés au Client. Tous les retours permis doivent être expédiés aux frais du Client et doivent
						être dans un excellent état de revente. Le matériel coupé selon les directives du Client ne peut être retourné.`,
				],
			},
			{
				title: "RETARD OU INEXÉCUTION",
				body: [
					`Ryerson n'est pas responsable des défauts ou des retards d'exécution aux termes des présentes
						causés, en totalité ou en partie, par des grèves, des arrêts de travail, des incendies, des actes de terrorisme, des accidents,
						des actes de guerre ou de rébellion, des mouvements populaires, des conflits publics, des mesures prises par tout gouvernement
						de nature juridique ou autre, des actes d'ennemis publics, force majeure, l'incapacité à obtenir des services de transport ou à
						les obtenir au moment opportun, l'incapacité à obtenir des biens, des matériaux ou de la main-d'œuvre qualifiée ou à les obtenir
						au moment opportun, ou toute autre cause qui échappe au contrôle raisonnable de Ryerson, notamment l'incapacité d'obtenir
						un produit ou de l'obtenir dans un délai opportun en raison des gestes posés par les fournisseurs de Ryerson.`,
				],
			},
			{
				title: "DÉFAUT",
				body: [
					`Le Client sera en défaut aux termes de la présente Convention si l'un des cas suivants survient : a) le Client refuse
						d'accepter l'expédition ou omet d'effectuer un paiement au moment où il est exigible; b) le Client déroge à un engagement ou
						à une entente; c) le Client décède, cesse d'exister, devient insolvable ou fait l'objet de procédures en matière de faillite,
						d'insolvabilité ou de liquidation; d) le Client tente de céder ses droits et obligations prévus dans la présente Convention sans le
						consentement préalable écrit de Ryerson; e) toute déclaration, garantie, condition ou attestation du Client ou toute information
						fournie par le Client dans la présente Convention ou en vertu de celle-ci est fausse à tout égard important lorsqu'elle est faite;
						f) Ryerson considère de bonne foi que le paiement ou l'exécution d'une obligation que le Client a envers Ryerson aux termes
						de la présente Convention ou de toute autre entente intervenue entre le Client et Ryerson ou un membre du groupe de Ryerson,
						y compris toute modification, tout renouvellement ou toute prolongation, est compromis.`,
				],
			},
			{
				title: "RECOURS EN CAS DE DÉFAUT",
				body: [
					`Si le Client est en défaut, a) le Client perdra le bénéfice du terme qui lui est accordé pour
						acquitter le prix d'achat des marchandises; b) Ryerson peut, à son gré, i) imputer au Client de l'intérêt sur les montants en
						souffrance à un taux annuel de 18 %, et/ou ii) reprendre possession immédiatement des marchandises et les retirer, et/ou
						déclarer que toutes les sommes impayées sont exigibles et payables immédiatement et/ou suspendre les expéditions au Client;
						c) une renonciation par Ryerson de faire valoir un manquement ou un défaut ne constitue pas une renonciation à faire valoir un
						manquement ou un défaut subséquent; d) Ryerson aura le droit de compenser tout montant dû par le Client ou par des entités
						apparentées du Client contre tout montant payable par Ryerson en lien avec toute somme impayée due au Client; e) Ryerson,
						à son gré et à son choix, a le droit de retenir toutes les sommes d'argent versées par Le Client à titre de dommages-intérêts tirés
						d'avance, et f) Ryerson peut se prévaloir des droits et des recours prévus par la loi en plus de tous les autres droits prévus dans
						les présentes, lesquels droits et recours sont cumulatifs.`,
				],
			},
			{
				title: "ÉQUIPEMENT",
				body: [
					`a) Tout équipement, y compris les gabarits, les matrices et les outils, que Ryerson acquiert aux fins de la
						production de marchandises pour le Client, est la propriété de Ryerson et demeurera en sa possession et sous son contrôle,
						et Ryerson peut, à son entière discrétion, modifier cet équipement. b) Les matériaux ou l'équipement que le Client fournit à
						Ryerson seront manipulés avec soin et entreposés par Ryerson tant qu'ils sont en sa possession. Si, pendant une période de
						dix-huit (18) mois consécutifs, le Client ne passe aucune commande considérée comme acceptable par Ryerson pour des
						marchandises fabriquées à partir de tel équipement ou de tels matériaux, Ryerson pourrait, moyennant un avis écrit au Client,
						demander que le Client dispose à ses frais de l'équipement ou des matériaux. Si le Client omet de se conformer à cet avis dans
						les trente (30) jours de la réception de l'avis écrit, Ryerson pourrait utiliser l'équipement ou les matériaux en question, ou en
						disposer, à son gré, sans que Ryerson n'ait de responsabilité ou d'obligation envers le Client, et le Client s'engage par les
						présentes à défendre, à indemniser et à tenir à couvert Ryerson (y compris les membres de son groupe et ses cessionnaires,
						administrateurs, dirigeants, employés, mandataires et représentants) à l'égard de toute réclamation déposée par des tiers qui
						découle de l'utilisation ou de la disposition des matériaux en question, ou qui s'y rapporte.`,
				],
			},
			{
				title: "ANNULATION",
				body: [
					`À la réception d'un avis écrit du Client, Ryerson doit annuler toute commande de la façon indiquée, sous
						réserve du droit de Ryerson (ou de ses sous-traitants) de poursuivre le traitement des matériaux bruts ou finis afin de les rendre
						à un point où l'interruption du processus engendre un minimum d'incidences et de frais pour Ryerson. Le Client est responsable
						de tous les coûts rattachés à l'annulation et à l'achèvement du traitement des matériaux. `,
				],
			},
			{
				title: "ACCEPTATION",
				body: [
					` La présente Convention liera le Client en raison de son achat de marchandises auprès de Ryerson et ne peut
						être modifiée qu'au moyen d'un document écrit, signé par Ryerson, et chacune des dispositions de la présente Convention
						demeure en vigueur tant qu'elle n'est pas explicitement contredite par le document écrit susmentionné, s'il y a lieu. Si le Client
						dépose un formulaire de commande renfermant des modalités contraires, ce formulaire de commande sera considéré
						uniquement comme une confirmation de la commande et ne modifiera, ne prévaudra, ne complémentera, ni ne remplacera
						d'aucune façon une disposition de la présente Convention. Le Client renonce à toute défense relativement à la validité ou à la
						force exécutoire de la présente Convention en raison de sa transmission par voie électronique au Client. Le Client reconnaît
						qu'il peut accéder à chaque URL mentionnée dans une soumission ou un autre document que Ryerson lui remet.`,
				],
			},
			{
				title: "GÉNÉRALITÉS",
				body: [
					`Ryerson peut céder ses droits et obligations prévus dans la présente Convention. Le Client ne peut céder ses
						obligations prévues dans la présente Convention à un tiers sans le consentement préalable écrit de Ryerson. Si le Client change
						son statut de constitution, tant le Client que ses successeurs continuent d'être liés par la présente Convention, et Ryerson
						conserve les droits et les recours dont elle dispose en cas de défaut. Une déclaration, une affirmation ou une Convention
						antérieure n'est exécutoire que si elle est mentionnée dans les présentes.`,
				],
			},
			{
				title: "UTILISATION À DES FINS NUCLÉAIRES",
				body: [
					`Les marchandises couvertes par la présente Convention et vendues par Ryerson
						ne sont pas destinées et n'ont pas été certifiées pour l'application ou la fabrication relativement à l'utilisation ou à la
						manipulation de matériel nucléaire ou à la construction, à l'exploitation ou à la maintenance d'installations nucléaires ou
						aménagement (une « utilisation à des fins nucléaires ») et Ryerson ne donne aucune représentation ou garantie, expresse ou
						implicite, à l'égard de la convenance ou la sécurité des marchandises pour l'utilisation à des fins nucléaires. Si le Client a
						l'intention d'utiliser les marchandises, ou de permettre à toute autre personne de s'en servir, pour une utilisation à des fins
						nucléaires, le Client sera le seul et unique responsable de s'assurer de la convenance et de la sécurité d'une telle utilisation
						et/ou du sur classement des marchandises pour juger une telle application et sécurité. Ryerson renonce à toute responsabilité
						relativement à tout dommage nucléaire ou autres dommages, blessure, perte de vie, contamination radioactive ou perte
						économique résultant de ou étant présumé résulter de toute utilisation à des fins nucléaires des marchandises par le Client ou
						toutes autres personnes, et le Client tiendra Ryerson à couvert de tout préjudice, l'indemnisera et le défendra (y compris les
						membres de son groupe, ses ayants droit, ses administrateurs, ses dirigeants, ses employés, ses représentants et ses mandataires)
						de tout(e) réclamation, perte, dommage, coût, poursuite, conséquence de poursuite, jugement, frais et responsabilité de quelque
						nature que ce soit (y compris, mais non de façon limitative, les frais légaux ainsi que les coûts et les débours relatifs à la
						défense) soit en contrat, action ou autre, qui, directement ou indirectement, sont de quelque façon que ce soit réputé être relié
						à, découler ou provenir de cette utilisation à des fins nucléaires.`,
				],
			},
			{
				title: "RÉSOLUTION DE DIFFÉRENDS/LOI APPLICABLE",
				body: [
					` La validité, l'interprétation et l'exécution de la présente
						Convention sont régies par les lois de la province de Québec et les lois fédérales du Canada qui s'y appliquent. Le Client s'en
						remet irrévocablement à la compétence non exclusive des tribunaux de la province de Québec. Les honoraires et débours
						d'avocats raisonnables sont accordés à la partie qui obtient gain de cause dans le cadre d'un litige.`,
				],
			},
			{
				title: "DIVISIBILITÉ",
				body: [
					`Si, dans un territoire, une disposition de la présente Convention ou son application à une partie ou dans
						certaines circonstances est restreinte, interdite ou non exécutoire, cette disposition sera, à l'égard de ce territoire, sans effet
						uniquement dans la mesure d'une telle restriction, interdiction ou inexigibilité, sans que les autres dispositions de la présente
						Convention soient invalidées, et sans incidence sur la validité ou la force `,
				],
			},
			{
				title: "INTERNATIONAL",
				body: [
					` La Convention des Nations Unies sur les contrats de vente internationale de marchandises ne s'applique
						pas à la présente Convention ni aux marchandises commandées ou livrées aux termes de la présente Convention. Chaque partie
						accepte de se conformer à l'ensemble des lois et des règlements applicables en matière de corruption d'agents publics et de
						lutte contre la corruption.`,
				],
			},
		],
	},
};

export const CanadaContentOntario: PrivacyPolicyBodyContent = {
	downloadLink: [
		"/static-assets/documents/pdfs/en/policies-terms-conditions/TermsAndConditionsCanadaQuebec_Rev3_2025.pdf",
		"/static-assets/documents/pdfs/en/policies-terms-conditions/TermsAndConditionsCanadaOntario_Rev3_2025.pdf"
	],
	downloadLabel: [
		"Download Ryerson's Canada Terms and Conditions for use in Quebec",
		"Download Ryerson's Canada Terms and Conditions for use in Ontario",
	],
	top: [`TERMS AND CONDITIONS OF SALE`, `RYERSON CANADA INC.`],
	list: {
		type: "ordered",
		listItems: [
			{
				title: "AGREEMENT",
				body: [
					`Whenever used herein, the term “Agreement” means these Terms and Conditions of Sale as may be amended
at the sole discretion of Ryerson Canada Inc. and its successors and assigns (“Ryerson”) at any time and from time to time,
together with the Credit Application and Agreement (if applicable). The term “goods” includes all goods or component parts
sold, whether manufactured by Ryerson or another supplier.`,
				],
			},
			{
				title: "PAYMENTS",
				body: [
					`Unless otherwise agreed by Ryerson in writing, all amounts payable hereunder shall be due to Ryerson within
						thirty (30) days of invoice date, time being of the essence. Late payments shall bear interest at the rate of 18% per annum or
						the highest rate permitted by law, whichever is less. All prices shown are net, and in addition to the price of goods, Customer
						shall pay all expenses including taxes, insurance, freight, carriage, and warehousing. All amounts shall be paid by the
						Customer to Ryerson without any setoff, deduction or recoupment`,
				],
			},
			{
				title: "TAXES AND PRICING",
				body: [
					`All prices quoted are subject to change without notice and are exclusive of taxes. Customer shall pay
all taxes resulting from transactions, including, without limitation, occupation, property, excise, sales, or use taxes, but
excluding any taxes based on the income of Ryerson. The purchase price shall be subject to increase based on Ryerson’s
established price at the date of actual shipment if shipment is delayed thirty (30) days or more beyond the scheduled shipment
date and such delay is caused in whole or in part by circumstances beyond the reasonable control of Ryerson as provided in
paragraph 11.`,
				],
			},
			{
				title: "SHIPMENT",
				body: [
					` (a) Scheduled shipment date is an estimate only. On or after the scheduled shipment date, Customer shall accept
shipment upon notification by Ryerson; or, if Customer refuses shipment, then Ryerson is authorized at its option to: (i) have
the goods transported and warehoused at Customer’s expense and risk, which shall constitute shipment to Customer, in which
event Ryerson may declare as immediately due all amounts owing upon shipment including the additional cost of such
transportation and warehousing; (ii) declare the monthly installment payments to commence thirty (30) days from the date of
such transportation and warehousing if any amounts are financed by Ryerson; or, (iii) defer shipment. (b) Ryerson may make
partial shipments hereunder and may invoice for each such partial shipment separately. Each partial Shipment will be deemed
to be a separate sale but a delay in delivery of any partial shipment will not relieve Customer of its obligation to accept delivery
of any remaining shipments. (c) Ryerson’s shipping weights will govern for each shipment or partial shipment. Should
Customer dispute the shipping weight of any shipment or partial shipment, Customer will notify Ryerson in writing prior to
the time of deemed acceptance specified in paragraph 7 of the reasons for such dispute and provide to Ryerson all necessary
documentation to substantiate any alleged discrepancy.`,
				],
			},
			{
				title: "SHIPPING TOLERANCES",
				body: [
					`The goods sold are subject either to Ryerson’s published shipping tolerances in effect on the date
of order, or if Ryerson has no such tolerances, then to applicable industry shipping tolerances for the goods in question.`,
				],
			},
			{
				title: "TITLE/RISK OF LOSS/INSURANCE",
				body: [
					`Title in the goods shall remain with Ryerson until such goods have been paid for in
full. However, such goods shall be entirely at Customer’s risk from the time they are placed in the possession of the carrier for
shipment to Customer. Customer shall ensure that the goods are insured against “all risks” from the time the goods are placed
in the possession of the carrier for shipment to Customer, and continuously thereafter until all amounts due to Ryerson are paid
in full. Such insurance shall be for no less than the total amount owing to Ryerson with loss first payable to Ryerson. Purchaser
shall indemnify Ryerson from all loss arising out of any claims, suits and demands by reason of the retention of title to the
goods by Ryerson while the goods are at the Customer’s risk.`,
				],
			},
			{
				title: "ACCEPTANCE OF GOODS",
				body: [
					`Customer shall inspect or test all goods upon receipt. Customer shall be deemed to have effected
final acceptance of the goods at the earliest of: (a) the fifteenth (15th) day after the date of shipment, unless written notice is
received by Ryerson before such day; or, (b) the date when the goods are used or otherwise placed in commercial operation.`,
				],
			},
			{
				title: "WARRANTY",
				body: [
					`(a) Ryerson warrants that title to the goods sold shall be free from any encumbrance, and that the goods will
conform to the description contained on Ryerson’s invoice. (b) SUBJECT TO APPLICABLE LAW, RYERSON MAKES NO
OTHER REPRESENTATIONS OR WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, EXCEPT AS
PROVIDED IN WRITING SIGNED BY AN AUTHORIZED OFFICER OF RYERSON, AND SPECIFICALLY EXCLUDES
ALL LEGAL OR IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY WARRANTY
AS TO MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. (c) Customer is responsible for the
designation and selection of product sold by Ryerson. Customer shall hold Ryerson harmless and indemnify and defend
Ryerson (including its affiliates, assigns, directors, officers, employees, agents and representatives) for any claims arising out
of or relating to the design, specification, or use of product(s) sold by Ryerson to Customer.`,
				],
			},
			{
				title: "CUSTOMER'S REMEDY",
				body: [
					`Ryerson’s sole responsibility and liability and Customer’s exclusive remedy under this Agreement
shall be limited to the repair or replacement of goods (f.o.b. Ryerson’s shipping point), or, at Ryerson’s option, return of the
goods and refund of moneys paid thereon, without interest, provided Customer is not in default hereunder. SUBJECT TOLEGAL_1:90731532.5
APPLICABLE LAW, IN NO EVENT SHALL RYERSON BE LIABLE FOR INDIRECT, INCIDENTAL,
CONSEQUENTIAL, OR EXEMPLARY DAMAGES, OR LEGAL FEES. Ryerson’s obligation hereunder is subject to receipt
of written notice of defect (containing detailed particulars of the alleged defect) from Customer prior to the time of deemed
acceptance specified in paragraph 7.`,
				],
			},
			{
				title: "RETURNS",
				body: [
					`Returned goods will be accepted only if Ryerson has given prior written consent. A charge for handling, inspection,
restocking and invoicing of up to 25% of the sale price of the returned goods shall be assessed against the Customer. All returns
allowed must be shipped at Customer’s expense and must be in excellent resale condition. Material cut to Customer’s
specifications is not returnable.`,
				],
			},
			{
				title: "DELAY OR NONPERFORMANCE",
				body: [
					`Ryerson shall not be liable for failure or delay in performance hereunder due in whole
or in part to strikes, work stoppages, fires, acts of terrorism, accidents, wars, rebellions, civil commotion, public strife, acts of
any government whether legal or otherwise, acts of public enemies, force majeure, inability to secure or delay in securing
transportation, inability to obtain or delays in obtaining goods, materials, or qualified labor, or any other causes beyond
Ryerson’s reasonable control, including without limitation delays or inability to obtain product because of the actions of
Ryerson’s suppliers.`,
				],
			},
			{
				title: "DEFAULT",
				body: [
					`Customer shall be in default under this Agreement if any of the following occurs: (a) Customer refuses to accept
shipment or fails to make any payment when due; (b) there is a breach of any covenant or agreement by Customer; (c) Customer
dies, ceases to exist, becomes insolvent or the subject of bankruptcy, insolvency, or liquidation proceedings; (d) Customer
attempts to assign its rights and obligations under this Agreement without the prior written consent of Ryerson; (e) any
representation, warranty, condition, or certification of Customer or any information provided by Customer in or pursuant to
this Agreement is false in any material respect when made; (f) Ryerson, in good faith, considers that payment or performance
of any obligation of Customer to Ryerson arising out of this Agreement or any other agreement between Customer and Ryerson
or any affiliate of Ryerson, including any modifications, renewals or extensions thereof, is impaired.`,
				],
			},
			{
				title: "REMEDIES UPON DEFAULT",
				body: [
					`In the event of Customer’s default: (a) Ryerson may, at its option, (i) charge Customer interest
at the rate of 18% per annum or the highest rate permitted by law, and/or (ii) take immediate possession of the goods and
remove same without notice and without legal proceedings, and/or declare all unpaid amounts immediately due and payable
and/or suspend shipments to Customer; (b) Waiver by Ryerson of any breach or default shall not constitute a waiver of any
subsequent breach or default; (c) Ryerson shall be entitled to set off any amount owed by Customer or any of Customer’s
related entities against any amount payable by Ryerson in connection with any unpaid monies due to Customer;
(d) Ryerson at its discretion and option shall be entitled to retain all money paid by Customer on account as liquidated damages;
and, (e) Ryerson shall have all the rights and remedies provided by law in addition to all other rights as established herein,
which rights and remedies shall be cumulative.`,
				],
			},
			{
				title: "EQUIPMENT",
				body: [
					`(a) Any equipment, including jigs, dies, and tools, which Ryerson acquires for use in the production of goods
for Customer, is the property of Ryerson and will remain in its possession and control, and Ryerson may at its sole discretion
modify any such equipment. (b) Any material or equipment furnished by Customer to Ryerson will be carefully handled and
stored by Ryerson while in Ryerson’s possession. When for eighteen (18) consecutive months no orders acceptable to Ryerson
are received from Customer for goods to be made from any such equipment or materials, Ryerson may by written notice request
that Customer make disposition thereof at Customer’s expense. If Customer fails to comply with such notice within thirty (30)
days of receipt of such written notice, Ryerson may make such use or disposition of said materials or equipment as it desires,
with no liability or obligation whatsoever to Customer, and Customer hereby agrees to hold Ryerson harmless and indemnify
and defend Ryerson (including its affiliates, assigns, directors, officers, employees, agents and representatives) against any
claims by third parties arising out of or relating to such use or disposition of the said materials.`,
				],
			},
			{
				title: "CANCELLATION",
				body: [
					`Upon receipt of written notice from Customer, Ryerson shall cancel any orders as instructed, subject to
Ryerson’s (or its subcontractors’) right to continue processing raw or finished material to the point at which processing can be
halted with the least disruption and cost to Ryerson. Customer shall be responsible for all costs associated with the cancellation
and completion of processing of material.`,
				],
			},
			{
				title: "ACCEPTANCE",
				body: [
					`This Agreement shall be binding on Customer by its purchase of goods from Ryerson and may only be
modified in writing, signed by Ryerson, and each of the provisions of this Agreement shall remain in effect unless and until
explicitly contradicted by the aforesaid writing. If Customer submits an order form with contrary terms or conditions, such
order form shall be considered only as confirmation of the order and shall in no way amend, prevail over, supplement, or
supersede any of the provisions of this Agreement. Customer waives any defense to the validity or enforceability of this
Agreement arising from any electronic submission of it to Customer. Customer acknowledges and agrees that it has the ability
to access each URL referenced in any quote or other document delivered to it by Ryerson.`,
				],
			},
			{
				title: "GENERAL",
				body: [
					`Ryerson may assign its rights and obligations under this Agreement. Customer may not assign its obligations
under this Agreement to any person without Ryerson’s prior written consent of Ryerson. If Customer changes its corporate
status, both Customer and its successors continue to be bound by this Agreement, and Ryerson reserves its rights and remedies
on default. No prior representation, affirmation, or agreement shall be enforceable unless set forth herein.`,
				],
			},
			{
				title: "NUCLEAR USE",
				body: [
					`The goods covered by this Agreement and sold by Ryerson are not intended for and have not been certified
for application or fabrication in connection with the use or handling of nuclear material or the construction, operation or
maintenance of a nuclear installation or facility ("Nuclear Use") and Ryerson gives no representation or warranty, express or
implied, as to the suitability or safety of such goods for any Nuclear Use. If Customer intends to use these goods or permit any
other person to use these goods for a Nuclear Use, Customer shall be solely responsible for satisfying itself as to their suitabilityLEGAL_1:90731532.5
and safety for such use and/or upgrading the goods to ensure such suitability and safety. Ryerson disclaims all liability for any
nuclear or other damages, injury, loss of life, radioactive contamination or economic loss resulting from or alleged to result
from any Nuclear Use of the goods by Customer or any other person, and Customer shall hold Ryerson harmless from and
indemnify and defend Ryerson (including its affiliates, successors, assigns, directors, officers, employees, agents and
representatives) against any and all claims, losses, damages, costs, actions, causes of action, judgments, expenses and liabilities
of every kind and nature whatsoever (including, without limitation, legal fees and costs and expenses of defense), whether in
contract, tort or otherwise, which, either directly or indirectly, are, or are alleged to in any way be connected with, arise out of
or result from such Nuclear Use.`,
				],
			},
			{
				title: "DISPUTE RESOLUTION/GOVERNING LAW",
				body: [
					`The validity, construction and enforcement of this Agreement are governed
by the laws of the province of Ontario and the federal laws of Canada applicable therein. The Customer irrevocably submits to
the non-exclusive jurisdiction of the courts of the province of Ontario. Reasonable legal fees and costs shall be awarded to the
prevailing party in any litigation. Customer must institute any action against Ryerson within one (1) year after Customer’s claim
arises, or, subject to applicable law, such claim shall be barred notwithstanding any statutory limitations to the contrary.`,
				],
			},
			{
				title: "SEVERABILITY",
				body: [
					`If, in any jurisdiction, any provision of this Agreement or its application to any party or circumstance is
restricted, prohibited, or unenforceable, such provision shall, as to such jurisdiction, be ineffective only to the extent of such
restriction, prohibition, or unenforceability, without invalidating the remaining provisions of this Agreement and without
affecting the validity or enforceability of such provision in any other jurisdiction or without affecting its application to other
party or circumstances.`,
				],
			},
			{
				title: "INTERNATIONAL",
				body: [
					`The United Nations Convention on Contracts for the International Sale of Goods shall not apply to this
Agreement or any goods ordered or issued under this Agreement. Each party agrees to comply with all applicable corruption
of public officials and antibribery laws and regulations.`,
				],
			},
			{
				title: "LANGUAGE",
				body: [
					`The parties to this Agreement confirm that it is their wish that this Agreement, as well as any other documents
relating to this Agreement, have been and shall be drawn up in the English language only. Les signataires confirment leur
volonté que la présente convention, de même que tous les documents s’y rattachant, soient rédigés en anglais seulement.`,
				],
			},
		],
	},
	bottom: [	
		`Rev. 3/2025`,
	],
};

export const CanadaFrenchContentOntario: PrivacyPolicyBodyContent = {
	downloadLink: [
		"/static-assets/documents/pdfs/fr/policies-terms-conditions/TermsAndConditionsCanadaQuebec_Rev3_2025_French.pdf",
		"/static-assets/documents/pdfs/fr/policies-terms-conditions/TermsAndConditionsCanadaOntario_Rev3_2025_French.pdf",
	],
	downloadLabel: [
		"Télécharger les conditions générales de Ryerson Canada pour une utilisation au Québec",
		"Télécharger les conditions générales de Ryerson Canada pour une utilisation en Ontario",
	],
	top: [`MODALITÉS ET CONDITIONS DE VENTE`, `RYERSON CANADA INC.`],
	list: {
		type: "ordered",
		listItems: [
			{
				title: "CONVENTION",
				body: [
					`Lorsqu’il est utilisé dans les présentes, le terme « Convention » désigne les présentes modalités et conditions
de vente, en leur version modifiée à la seule discrétion de Ryerson Canada Inc. et de ses successeurs et ayants droit
(« Ryerson ») en tout temps et de temps à autre, ainsi que la Demande de crédit et la Convention (le cas échéant). Le terme
« marchandises » inclut toutes les marchandises ou les composantes vendues, qu’elles soient fabriquées par Ryerson ou par un
autre fournisseur.`,
				],
			},
			{
				title: "PAIEMENTS",
				body: [
					`Sauf si Ryerson en convient autrement par écrit, toutes les sommes payables aux termes des présentes doivent
être versées à Ryerson dans les trente (30) jours suivant la date de facturation, le délai étant de rigueur. Les paiements en
souffrance portent intérêt au taux le plus bas entre 18 % par année et le plus haut taux permis par la loi. Tous les prix affichés
sont nets et, en sus du prix des marchandises, le Client est tenu de payer tous les frais, y compris les taxes, les primes
d’assurances et les frais d’expédition, de transport et d’entreposage. Le Client doit verser à Ryerson la totalité des sommes,
sans compensation, déduction ou retenue. Ryerson accepte les paiements par carte de crédit. Si le paiement est fait par carte de
crédit, le Client permet expressément à Ryerson de lui facturer et de porter au compte de sa carte de crédit i) les frais applicables,
les taxes applicables et toute autre somme étayée sur une facture, et, ii) sauf si la loi l’interdit, les autres frais engagés par
Ryerson par suite de l’acceptation des paiements par carte de crédit pour la facture en question, y compris notamment des frais
supplémentaires applicables au solde total de la facture, dans la mesure où ils ne dépassent pas le coût assumé par Ryerson
pour l’acceptation de ces paiements. Ryerson fournira au Client une facture et un reçu qui prévoient le paiement des éléments
établis aux points i) et ii) ci-dessus. Ces frais, ces taxes et ces sommes connexes seront portés au compte de la carte de crédit
que le Client désigne au moment où de tels frais ou une telle somme sont exigibles ou payables. Le Client autorise Ryerson,
les membres de son groupe ou ses prestataires de services à traiter et à stocker les renseignements de paiement du Client.`,
				],
			},
			{
				title: "TAXES ET PRIX",
				body: [
					`Tous les prix affichés peuvent changer sans préavis et ne comprennent pas les taxes. Le Client doit acquitter
l’ensemble des taxes rattachées aux transactions, y compris notamment les taxes sur l’occupation, les taxes foncières, la taxe
d’accise, les taxes de vente ou les taxes d’utilisation, mais à l’exception des taxes et impôts fondés sur le revenu de Ryerson.
Le prix d’achat peut être supérieur au prix établi par Ryerson au moment de l’expédition effective si l’expédition est retardée
de trente (30) jours ou plus après la date d’expédition prévue, et que ce retard est causé, en totalité ou en partie, par des
circonstances qui échappent au contrôle raisonnable de Ryerson, comme il est prévu au paragraphe 11.`,
				],
			},
			{
				title: "EXPÉDITION",
				body: [
					`a) La date d’expédition prévue est une estimation seulement. À compter de la date d’expédition prévue, le
Client doit accepter l’expédition à la réception d’un avis de Ryerson; ou, si le Client refuse l’expédition, Ryerson a le droit de
faire ce qui suit, à son gré : i) transporter et entreposer les marchandises aux frais et aux risques du Client, ce qui constitue une
expédition au Client, auquel cas Ryerson peut déclarer que toutes les sommes payables à l’expédition, y compris les frais
supplémentaires rattachés au transport et à l’entreposage, sont payables immédiatement; ii) déclarer que les versements
mensuels commencent trente (30) jours à compter de la date d’un tel transport et entreposage si des sommes sont financées par
Ryerson, ou iii) reporter l’expédition. b) Ryerson peut effectuer des expéditions partielles aux termes des présentes et peut
facturer chacune de ces livraisons partielles de façon distincte. Chaque expédition partielle sera réputée constituer une vente
distincte, mais un retard de livraison d’une expédition partielle ne libérera pas le Client de son obligation d’accepter la livraison
des autres envois. c) Chaque envoi ou envoi partiel sera régi par les poids d’expédition de Ryerson. Le Client qui conteste le
poids d’expédition d’une expédition ou d’une expédition partielle doit aviser Ryerson, par écrit et avant le moment de
l’acceptation réputée indiquée au paragraphe 7, des motifs de sa contestation et fournir à Ryerson tous les documents
nécessaires en appui à la différence alléguée.`,
				],
			},
			{
				title: "TOLÉRANCES D'EXPÉDITION",
				body: [
					`Les marchandises vendues sont assujetties aux tolérances d’expédition publiées par
Ryerson en vigueur à la date de la commande ou, si Ryerson n’applique aucune tolérance de ce genre, aux tolérances
d’expédition applicables dans l’industrie pour les marchandises en question.`,
				],
			},
			{
				title: "PROPRIÉTÉ/RISQUE DE PERTE/ASSURANCE",
				body: [
					`Ryerson conserve le titre des marchandises jusqu’à ce que ces
marchandises aient été payées en totalité. Toutefois, les risques rattachés à ces marchandises sont entièrement assumés par le
Client dès le moment où elles se trouvent entre les mains de l’expéditeur aux fins de livraison au Client. Le Client doit s’assurer
que les marchandises sont couvertes par une assurance « tous risques » à compter du moment où elles sont placées entre les
mains de l’expéditeur aux fins de livraison au Client, et ce, continuellement par la suite jusqu’à ce que toutes les sommes
payables à Ryerson soient intégralement acquittées. Cette assurance doit couvrir au minimum le montant total de la somme
payable à Ryerson, la perte étant d’abord payable à Ryerson. L’acheteur indemnisera Ryerson à l’égard de toutes les pertes
découlant de toute réclamation, poursuite ou demande en raison de la réserve de propriété des marchandises par Ryerson alors
que les risques rattachés aux marchandises sont assumés par le Client.`,
				],
			},
			{
				title: "ACCEPTATION DES MARCHANDISES",
				body: [
					`Le Client doit inspecter ou tester toutes les marchandises dès leur réception. Le
Client sera réputé avoir procédé à l’acceptation finale des marchandises à la date la plus rapprochée parmi les suivantes : a) le
quinzième (15e) jour suivant la date d’expédition, sauf si Ryerson reçoit un avis écrit avant ce jour; ou b) la date à laquelle les
marchandises sont utilisées ou sont autrement mises en exploitation commerciale.`,
				],
			},
			{
				title: "GARANTIE",
				body: [
					`a) Ryerson garantit que les titres de propriété des marchandises vendues sont libres de toutes charges et que les
marchandises seront conformes à la description qui figure sur la facture de Ryerson. b) SOUS RÉSERVE DES LOIS
APPLICABLES, RYERSON NE FAIT AUCUNE AUTRE DÉCLARATION ET NE DONNE AUCUNE AUTRE
GARANTIE NI NE POSE DE CONDITION, EXPRESSE OU IMPLICITE, QUE CELLES QUI SONT DÉCRITES DANS
UN DOCUMENT ÉCRIT SIGNÉ PAR UN DIRIGEANT AUTORISÉ DE RYERSON, ET EXCLUT EXPRESSÉMENT
TOUTE GARANTIE LÉGALE OU IMPLICITE, Y COMPRIS, MAIS NON DE FAÇON LIMITATIVE, TOUTE
GARANTIE QUANT À LA QUALITÉ MARCHANDE OU À L’ADÉQUATION À UN USAGE PARTICULIER. c) Le
Client est responsable de la désignation et de la sélection de produits vendus par Ryerson. Le Client doit défendre, indemniser
et tenir à couvert Ryerson (y compris les membres de son groupe et ses cessionnaires, administrateurs, dirigeants, employés,
mandataires et représentants) à l’égard de l’ensemble des réclamations qui découlent de la conception, des spécifications ou de
l’utilisation du ou des produits que Ryerson vend au Client, ou qui s’y rapportent.`,
				],
			},
			{
				title: "RECOURS DU CLIENT",
				body: [
					`La seule responsabilité et obligation de Ryerson et l’unique recours du Client aux termes de la
présente Convention se limitent à la réparation ou au remplacement des marchandises (FOB au point d’expédition de Ryerson)
ou, au gré de Ryerson, au retour des marchandises et au remboursement des sommes payées pour celles-ci, sans intérêt, pourvu
que le Client ne se trouve pas en situation de défaut aux termes des présentes. SOUS RÉSERVE DES LOIS APPLICABLES,
RYERSON NE PEUT EN AUCUN CAS ÊTRE TENUE RESPONSABLE DES DOMMAGES-INTÉRÊTS INDIRECTS,
ACCESSOIRES, CONSÉCUTIFS OU EXEMPLAIRES, OU DES FRAIS JURIDIQUES. L’obligation de Ryerson aux termes
des présentes est conditionnelle à la réception d’un avis de défaut écrit (qui détaille le défaut allégué) remis par le Client avant
l’acceptation réputée indiquée au paragraphe 7.`,
				],
			},
			{
				title: "RETOURS",
				body: [
					`Les marchandises retournées seront acceptées uniquement si Ryerson a donné son consentement écrit au préalable.
Des frais de manutention, d’inspection, de remise en stock et de facturation, pouvant aller jusqu’à 25 % du prix de vente des
marchandises retournées, seront imposés au Client. Tous les retours permis doivent être expédiés aux frais du Client et doivent
être dans un excellent état de revente. Le matériel coupé selon les directives du Client ne peut être retourné.`,
				],
			},
			{
				title: "RETARD OU INEXÉCUTION",
				body: [
					`Ryerson n’est pas responsable des défauts ou des retards d’exécution aux termes des présentes
causés, en totalité ou en partie, par des grèves, des arrêts de travail, des incendies, des actes de terrorisme, des accidents,
des actes de guerre ou de rébellion, des mouvements populaires, des conflits publics, des mesures prises par tout gouvernement
de nature juridique ou autre, des actes d’ennemis publics, force majeure, l’incapacité à obtenir des services de transport ou à
les obtenir au moment opportun, l’incapacité à obtenir des biens, des matériaux ou de la main-d’œuvre qualifiée ou à les obtenir
au moment opportun, ou toute autre cause qui échappe au contrôle raisonnable de Ryerson, notamment l’incapacité d’obtenir
un produit ou de l’obtenir dans un délai opportun en raison des gestes posés par les fournisseurs de Ryerson.`,
				],
			},
			{
				title: "DÉFAUT",
				body: [
					`Le Client sera en défaut aux termes de la présente Convention si l’un des cas suivants survient : a) le Client refuse
d’accepter l’expédition ou omet d’effectuer un paiement au moment où il est exigible; b) le Client déroge à un engagement ou
à une entente; c) le Client décède, cesse d’exister, devient insolvable ou fait l’objet de procédures en matière de faillite,
d’insolvabilité ou de liquidation; d) le Client tente de céder ses droits et obligations prévus dans la présente Convention sans le
consentement préalable écrit de Ryerson; e) toute déclaration, garantie, condition ou attestation du Client ou toute information
fournie par le Client dans la présente Convention ou en vertu de celle-ci est fausse à tout égard important lorsqu’elle est faite;
f) Ryerson considère de bonne foi que le paiement ou l’exécution d’une obligation que le Client a envers Ryerson aux termes
de la présente Convention ou de toute autre entente intervenue entre le Client et Ryerson ou un membre du groupe de Ryerson,
y compris toute modification, tout renouvellement ou toute prolongation, est compromis.`,
				],
			},
			{
				title: "RECOURS EN CAS DE DÉFAUT",
				body: [
					`Si le Client est en défaut, a) Ryerson peut, à son gré, i) imputer au Client de l’intérêt à un
taux annuel de 18 % ou au plus haut taux permis par la loi, et/ou ii) reprendre possession immédiatement des marchandises et
les retirer, sans préavis ou autre procédure judiciaire, et/ou déclarer que toutes les sommes impayées sont exigibles et payables
immédiatement et/ou suspendre les expéditions au Client; b) une renonciation par Ryerson de faire valoir un manquement ou
un défaut ne constitue pas une renonciation à faire valoir un manquement ou un défaut subséquent; c) Ryerson aura le droit de
compenser tout montant dû par le Client ou par des entités apparentées du Client contre tout montant payable par Ryerson en
lien avec toute somme impayée due au Client; d) Ryerson, à son gré et à son choix, a le droit de retenir toutes les sommes
d’argent versées par Le Client à titre de dommages-intérêts tirés d’avance, et e) Ryerson peut se prévaloir des droits et des
recours prévus par la loi en plus de tous les autres droits prévus dans les présentes, lesquels droits et recours sont cumulatifs.`,
				],
			},
			{
				title: "ÉQUIPEMENT",
				body: [
					`a) Tout équipement, y compris les gabarits, les matrices et les outils, que Ryerson acquiert aux fins de la
production de marchandises pour le Client, est la propriété de Ryerson et demeurera en sa possession et sous son contrôle,
et Ryerson peut, à son entière discrétion, modifier cet équipement. b) Les matériaux ou l’équipement que le Client fournit à
Ryerson seront manipulés avec soin et entreposés par Ryerson tant qu’ils sont en sa possession. Si, pendant une période de
dix-huit (18) mois consécutifs, le Client ne passe aucune commande considérée comme acceptable par Ryerson pour des
marchandises fabriquées à partir de tel équipement ou de tels matériaux, Ryerson pourrait, moyennant un avis écrit au Client,
demander que le Client dispose à ses frais de l’équipement ou des matériaux. Si le Client omet de se conformer à cet avis dans
les trente (30) jours de la réception de l’avis écrit, Ryerson pourrait utiliser l’équipement ou les matériaux en question, ou en
disposer, à son gré, sans que Ryerson n’ait de responsabilité ou d’obligation envers le Client, et le Client s’engage par les
présentes à défendre, à indemniser et à tenir à couvert Ryerson (y compris les membres de son groupe et ses cessionnaires,
administrateurs, dirigeants, employés, mandataires et représentants) à l’égard de toute réclamation déposée par des tiers qui
découle de l’utilisation ou de la disposition des matériaux en question, ou qui s’y rapporte.`,
				],
			},
			{
				title: "ANNULATION",
				body: [
					`À la réception d’un avis écrit du Client, Ryerson doit annuler toute commande de la façon indiquée, sous
réserve du droit de Ryerson (ou de ses sous-traitants) de poursuivre le traitement des matériaux bruts ou finis afin de les rendre
à un point où l’interruption du processus engendre un minimum d’incidences et de frais pour Ryerson. Le Client est responsable
de tous les coûts rattachés à l’annulation et à l’achèvement du traitement des matériaux.`,
				],
			},
			{
				title: "ACCEPTATION",
				body: [
					`La présente Convention liera le Client en raison de son achat de marchandises auprès de Ryerson et ne peut
être modifiée qu’au moyen d’un document écrit, signé par Ryerson, et chacune des dispositions de la présente Convention
demeure en vigueur tant qu’elle n’est pas explicitement contredite par le document écrit susmentionné, s’il y a lieu. Si le Client
dépose un formulaire de commande renfermant des modalités contraires, ce formulaire de commande sera considéré
uniquement comme une confirmation de la commande et ne modifiera, ne prévaudra, ne complémentera, ni ne remplacera
d’aucune façon une disposition de la présente Convention. Le Client renonce à toute défense relativement à la validité ou à la
force exécutoire de la présente Convention en raison de sa transmission par voie électronique au Client. Le Client reconnaît
qu’il peut accéder à chaque URL mentionnée dans une soumission ou un autre document que Ryerson lui remet.`,
				],
			},
			{
				title: "GÉNÉRALITÉS",
				body: [
					`Ryerson peut céder ses droits et obligations prévus dans la présente Convention. Le Client ne peut céder ses
obligations prévues dans la présente Convention à un tiers sans le consentement préalable écrit de Ryerson. Si le Client change
son statut de constitution, tant le Client que ses successeurs continuent d’être liés par la présente Convention, et Ryerson
conserve les droits et les recours dont elle dispose en cas de défaut. Une déclaration, une affirmation ou une Convention
antérieure n’est exécutoire que si elle est mentionnée dans les présentes.`,
				],
			},
			{
				title: "UTILISATION À DES FINS NUCLÉAIRES",
				body: [
					`Les marchandises couvertes par la présente Convention et vendues par Ryerson
ne sont pas destinées et n’ont pas été certifiées pour l’application ou la fabrication relativement à l’utilisation ou à la
manipulation de matériel nucléaire ou à la construction, à l’exploitation ou à la maintenance d’installations nucléaires ou
aménagement (une « utilisation à des fins nucléaires ») et Ryerson ne donne aucune représentation ou garantie, expresse ou
implicite, à l’égard de la convenance ou la sécurité des marchandises pour l’utilisation à des fins nucléaires. Si le Client a
l’intention d’utiliser les marchandises, ou de permettre à toute autre personne de s’en servir, pour une utilisation à des fins
nucléaires, le Client sera le seul et unique responsable de s’assurer de la convenance et de la sécurité d’une telle utilisation
et/ou du sur classement des marchandises pour juger une telle application et sécurité. Ryerson renonce à toute responsabilité
relativement à tout dommage nucléaire ou autres dommages, blessure, perte de vie, contamination radioactive ou perte
économique résultant de ou étant présumé résulter de toute utilisation à des fins nucléaires des marchandises par le Client ou
toutes autres personnes, et le Client tiendra Ryerson à couvert de tout préjudice, l’indemnisera et le défendra (y compris les
membres de son groupe, ses ayants droit, ses administrateurs, ses dirigeants, ses employés, ses représentants et ses mandataires)
de tout(e) réclamation, perte, dommage, coût, poursuite, conséquence de poursuite, jugement, frais et responsabilité de quelque
nature que ce soit (y compris, mais non de façon limitative, les frais légaux ainsi que les coûts et les débours relatifs à la
défense) soit en contrat, action ou autre, qui, directement ou indirectement, sont de quelque façon que ce soit réputé être relié
à, découler ou provenir de cette utilisation à des fins nucléaires.`,
				],
			},
			{
				title: "RÉSOLUTION DE DIFFÉRENDS/LOI APPLICABLE",
				body: [
					`La validité, l’interprétation et l’exécution de la présente
Convention sont régies par les lois de la province de l’Ontario et les lois fédérales du Canada qui s’y appliquent. Le Client s’en
remet irrévocablement à la compétence non exclusive des tribunaux de la province de l’Ontario. Les honoraires et débours
d’avocats raisonnables sont accordés à la partie qui obtient gain de cause dans le cadre d’un litige. Le Client qui souhaite
intenter une action en justice contre Ryerson dispose pour ce faire d’un délai d’un (1) an après la survenance des faits sur
lesquels se fondent la réclamation du Client, à défaut de quoi, sous réserve des lois applicables, cette réclamation sera prescrite,
sans égard à tout autre délai légal de prescription.`,
				],
			},
			{
				title: "DIVISIBILITÉ",
				body: [
					`Si, dans un territoire, une disposition de la présente Convention ou son application à une partie ou dans
certaines circonstances est restreinte, interdite ou non exécutoire, cette disposition sera, à l’égard de ce territoire, sans effet
uniquement dans la mesure d’une telle restriction, interdiction ou inexigibilité, sans que les autres dispositions de la présente
Convention soient invalidées, et sans incidence sur la validité ou la force exécutoire de cette disposition dans un autre territoire
ni sur son application à d’autres parties ou dans d’autres circonstances.`,
				],
			},
			{
				title: "INTERNATIONAL",
				body: [
					`La Convention des Nations Unies sur les contrats de vente internationale de marchandises ne s’applique
pas à la présente Convention ni aux marchandises commandées ou livrées aux termes de la présente Convention. Chaque partie
accepte de se conformer à l’ensemble des lois et des règlements applicables en matière de corruption d’agents publics et de
lutte contre la corruption.`,
				],
			},
		],
	},
	bottom: [
		`Rév. 3/2025`,
	]
};

export const CanadaFrenchContentQuebec: PrivacyPolicyBodyContent = {
	downloadLink: [
		"/static-assets/documents/pdfs/fr/policies-terms-conditions/TermsAndConditionsCanadaQuebec_Rev3_2025_French.pdf",
		"/static-assets/documents/pdfs/fr/policies-terms-conditions/TermsAndConditionsCanadaOntario_Rev3_2025_French.pdf",
	],
	downloadLabel: [
		"Télécharger les conditions générales de Ryerson Canada pour une utilisation au Québec",
		"Télécharger les conditions générales de Ryerson Canada pour une utilisation en Ontario",
	],
	top: [`MODALITÉS ET CONDITIONS DE VENTE`, `RYERSON CANADA INC.`],
	list: {
		type: "ordered",
		listItems: [
			{
				title: "CONVENTION",
				body: [
					`Lorsqu’il est utilisé dans les présentes, le terme « Convention » désigne les présentes modalités et conditions
de vente, en leur version modifiée à la seule discrétion de Ryerson Canada Inc. et de ses successeurs et ayants droit
(« Ryerson ») en tout temps et de temps à autre, ainsi que la Demande de crédit et la Convention (le cas échéant). Le terme
« marchandises » inclut toutes les marchandises ou les composantes vendues, qu’elles soient fabriquées par Ryerson ou par un
autre fournisseur. Le terme « Client » désigne le client qui achète des marchandises auprès de Ryerson aux termes de la présente
Convention.`,
				],
			},
			{
				title: "PAIEMENTS",
				body: [
					`Sauf si Ryerson en convient autrement par écrit, toutes les sommes payables aux termes des présentes doivent
être versées à Ryerson dans les trente (30) jours suivant la date de facturation, le délai étant de rigueur. Les paiements en
souffrance portent intérêt au taux de 18 % par année. Tous les prix affichés sont nets et, en sus du prix des marchandises, le
Client est tenu de payer tous les frais, y compris les taxes, les droits, les primes d’assurances et les frais d’expédition, de
transport et d’entreposage. Le Client doit verser à Ryerson la totalité des sommes, sans compensation, déduction ou retenue.
Ryerson accepte les paiements par carte de crédit. Si le paiement est fait par carte de crédit, le Client permet expressément à
Ryerson de lui facturer et de porter au compte de sa carte de crédit i) les frais applicables, les taxes applicables et toute autre
somme étayée sur une facture, et, ii) sauf si la loi l’interdit, les autres frais engagés par Ryerson par suite de l’acceptation des
paiements par carte de crédit pour la facture en question, y compris notamment des frais supplémentaires applicables au solde
total de la facture, dans la mesure où ils ne dépassent pas le coût assumé par Ryerson pour l’acceptation de ces paiements.
Ryerson fournira au Client une facture et un reçu qui prévoient le paiement des éléments établis aux points i) et ii) ci-dessus.
Ces frais, ces taxes et ces sommes connexes seront portés au compte de la carte de crédit que le Client désigne au moment où
de tels frais ou une telle somme sont exigibles ou payables. Le Client autorise Ryerson, les membres de son groupe ou ses
prestataires de services à traiter et à stocker les renseignements de paiement du Client.`,
				],
			},
			{
				title: "TAXES ET PRIX",
				body: [
					`Tous les prix affichés peuvent changer sans préavis et ne comprennent pas les taxes. Le Client doit acquitter
l’ensemble des taxes et des droits rattachés aux opérations, y compris notamment les droits d’importation, les taxes sur
l’occupation, les taxes foncières, la taxe d’accise, la taxe sur les biens et services, les taxes de vente ou les taxes d’utilisation,
mais à l’exception des taxes et impôts fondés sur le revenu de Ryerson. Le prix d’achat peut être supérieur au prix établi par
Ryerson au moment de l’expédition effective si l’expédition est retardée de trente (30) jours ou plus après la date d’expédition
prévue, et que ce retard est causé, en totalité ou en partie, par des circonstances qui échappent au contrôle raisonnable de
Ryerson, comme il est prévu au paragraphe 11.`,
				],
			},
			{
				title: "EXPÉDITION",
				body: [
					`a) La date d’expédition prévue est une estimation seulement. À compter de la date d’expédition prévue, le
Client doit accepter l’expédition à la réception d’un avis de Ryerson; ou, si le Client refuse l’expédition, Ryerson a le droit de
faire ce qui suit, à son gré : i) transporter et entreposer les marchandises aux frais et aux risques du Client, ce qui constitue une
expédition au Client, auquel cas Ryerson peut déclarer que toutes les sommes payables à l’expédition, y compris les frais
supplémentaires rattachés au transport et à l’entreposage, sont payables immédiatement; ii) déclarer que les versements
mensuels commencent trente (30) jours à compter de la date d’un tel transport et entreposage si des sommes sont financées par
Ryerson, ou iii) reporter l’expédition. b) Ryerson peut effectuer des expéditions partielles aux termes des présentes et peut
facturer chacune de ces livraisons partielles de façon distincte. Chaque expédition partielle sera réputée constituer une vente
distincte, mais un retard de livraison d’une expédition partielle ne libérera pas le Client de son obligation d’accepter la livraison
des autres envois. c) Chaque envoi ou envoi partiel sera régi par les poids d’expédition de Ryerson. Le Client qui conteste le
poids d’expédition d’une expédition ou d’une expédition partielle doit aviser Ryerson, par écrit et avant le moment de
l’acceptation réputée indiquée au paragraphe 7, des motifs de sa contestation et fournir à Ryerson tous les documents
nécessaires en appui à la différence alléguée.`,
				],
			},
			{
				title: "TOLÉRANCES D'EXPÉDITION",
				body: [
					`Les marchandises vendues sont assujetties aux tolérances d’expédition publiées par
Ryerson en vigueur à la date de la commande ou, si Ryerson n’applique aucune tolérance de ce genre, aux tolérances
d’expédition applicables dans l’industrie pour les marchandises en question.`,
				],
			},
			{
				title: "PROPRIÉTÉ/RISQUE DE PERTE/ASSURANCE",
				body: [
					`Ryerson conserve la propriété des marchandises jusqu’à ce que ces
marchandises aient été payées en totalité. Toutefois, les risques rattachés à ces marchandises sont entièrement assumés par le
Client dès le moment où elles se trouvent entre les mains de l’expéditeur aux fins de livraison au Client. Le Client doit s’assurer
que les marchandises sont couvertes par une assurance « tous risques » à compter du moment où elles sont placées entre les
mains de l’expéditeur aux fins de livraison au Client, et ce, continuellement par la suite jusqu’à ce que toutes les sommes
payables à Ryerson soient intégralement acquittées. Cette assurance doit couvrir au minimum le montant total de la somme
payable à Ryerson, la perte étant d’abord payable à Ryerson. Le Client indemnisera Ryerson à l’égard de toutes les pertes
découlant de toute réclamation, poursuite ou demande en raison de la réserve de propriété des marchandises par Ryerson alors
que les risques rattachés aux marchandises sont assumés par le Client.`,
				],
			},
			{
				title: "ACCEPTATION DES MARCHANDISES",
				body: [
					`Le Client doit inspecter ou tester toutes les marchandises dès leur réception. Le
Client sera réputé avoir procédé à l’acceptation finale des marchandises à la date la plus rapprochée parmi les suivantes : a) le
quinzième (15e) jour suivant la date d’expédition, sauf si Ryerson reçoit un avis écrit avant ce jour; ou b) la date à laquelle les
marchandises sont utilisées ou sont autrement mises en exploitation commerciale.`,
				],
			},
			{
				title: "GARANTIE",
				body: [
					`a) Ryerson garantit que les titres de propriété des marchandises vendues sont libres de toutes charges
(à l’exception de la réserve de propriété en faveur de Ryerson jusqu’à ce que le prix des marchandises ait été acquitté
intégralement), et que les marchandises seront conformes à la description qui figure sur la facture de Ryerson. b) SOUS
RÉSERVE DES LOIS APPLICABLES, RYERSON NE FAIT AUCUNE AUTRE DÉCLARATION ET NE DONNE
AUCUNE AUTRE GARANTIE NI NE POSE DE CONDITION, EXPRESSE OU IMPLICITE, QUE CELLES QUI SONT
DÉCRITES DANS UN DOCUMENT ÉCRIT SIGNÉ PAR UN DIRIGEANT AUTORISÉ DE RYERSON, ET EXCLUT
EXPRESSÉMENT TOUTE GARANTIE LÉGALE OU IMPLICITE, Y COMPRIS, MAIS NON DE FAÇON LIMITATIVE,
TOUTE GARANTIE QUANT À LA QUALITÉ MARCHANDE OU À L’ADÉQUATION À UN USAGE PARTICULIER.
c) Le Client est responsable de la désignation et de la sélection de produits vendus par Ryerson. Le Client doit défendre,
indemniser et tenir à couvert Ryerson (y compris les membres de son groupe et ses cessionnaires, administrateurs, dirigeants,
employés, mandataires et représentants) à l’égard de l’ensemble des réclamations qui découlent de la conception,
des spécifications ou de l’utilisation du ou des produits que Ryerson vend au Client, ou qui s’y rapportent.`,
				],
			},
			{
				title: "RECOURS DU CLIENT",
				body: [
					`La seule responsabilité et obligation de Ryerson et l’unique recours du Client aux termes de la
présente Convention se limitent à la réparation ou au remplacement des marchandises (FOB au point d’expédition de Ryerson)
ou, au gré de Ryerson, au retour des marchandises et au remboursement des sommes payées pour celles-ci, sans intérêt, pourvu
que le Client ne se trouve pas en situation de défaut aux termes des présentes. SOUS RÉSERVE DES LOIS APPLICABLES,
RYERSON NE PEUT EN AUCUN CAS ÊTRE TENUE RESPONSABLE DES DOMMAGES-INTÉRÊTS INDIRECTS,
ACCESSOIRES, CONSÉCUTIFS OU EXEMPLAIRES, DE LA PERTE DE PROFITS OU DES FRAIS JURIDIQUES.
L’obligation de Ryerson aux termes des présentes est conditionnelle à la réception d’un avis de défaut écrit (qui détaille le
défaut allégué) remis par le Client avant l’acceptation réputée indiquée au paragraphe 7.`,
				],
			},
			{
				title: "RETOURS",
				body: [
					`Les marchandises retournées seront acceptées uniquement si Ryerson a donné son consentement écrit au préalable.
Des frais de manutention, d’inspection, de remise en stock et de facturation, pouvant aller jusqu’à 25 % du prix de vente des
marchandises retournées, seront imposés au Client. Tous les retours permis doivent être expédiés aux frais du Client et doivent
être dans un excellent état de revente. Le matériel coupé selon les directives du Client ne peut être retourné.`,
				],
			},
			{
				title: "RETARD OU INEXÉCUTION",
				body: [
					`Ryerson n’est pas responsable des défauts ou des retards d’exécution aux termes des présentes
causés, en totalité ou en partie, par des grèves, des arrêts de travail, des incendies, des actes de terrorisme, des accidents,
des actes de guerre ou de rébellion, des mouvements populaires, des conflits publics, des mesures prises par tout gouvernement
de nature juridique ou autre, des actes d’ennemis publics, force majeure, l’incapacité à obtenir des services de transport ou à
les obtenir au moment opportun, l’incapacité à obtenir des biens, des matériaux ou de la main-d’œuvre qualifiée ou à les obtenir
au moment opportun, ou toute autre cause qui échappe au contrôle raisonnable de Ryerson, notamment l’incapacité d’obtenir
un produit ou de l’obtenir dans un délai opportun en raison des gestes posés par les fournisseurs de Ryerson.`,
				],
			},
			{
				title: "DÉFAUT",
				body: [
					`Le Client sera en défaut aux termes de la présente Convention si l’un des cas suivants survient : a) le Client refuse
d’accepter l’expédition ou omet d’effectuer un paiement au moment où il est exigible; b) le Client déroge à un engagement ou
à une entente; c) le Client décède, cesse d’exister, devient insolvable ou fait l’objet de procédures en matière de faillite,
d’insolvabilité ou de liquidation; d) le Client tente de céder ses droits et obligations prévus dans la présente Convention sans le
consentement préalable écrit de Ryerson; e) toute déclaration, garantie, condition ou attestation du Client ou toute information
fournie par le Client dans la présente Convention ou en vertu de celle-ci est fausse à tout égard important lorsqu’elle est faite;
f) Ryerson considère de bonne foi que le paiement ou l’exécution d’une obligation que le Client a envers Ryerson aux termes
de la présente Convention ou de toute autre entente intervenue entre le Client et Ryerson ou un membre du groupe de Ryerson,
y compris toute modification, tout renouvellement ou toute prolongation, est compromis.`,
				],
			},
			{
				title: "RECOURS EN CAS DE DÉFAUT",
				body: [
					`Si le Client est en défaut, a) le Client perdra le bénéfice du terme qui lui est accordé pour
acquitter le prix d’achat des marchandises; b) Ryerson peut, à son gré, i) imputer au Client de l’intérêt sur les montants en
souffrance à un taux annuel de 18 %, et/ou ii) reprendre possession immédiatement des marchandises et les retirer, et/ou
déclarer que toutes les sommes impayées sont exigibles et payables immédiatement et/ou suspendre les expéditions au Client;
c) une renonciation par Ryerson de faire valoir un manquement ou un défaut ne constitue pas une renonciation à faire valoir un
manquement ou un défaut subséquent; d) Ryerson aura le droit de compenser tout montant dû par le Client ou par des entités
apparentées du Client contre tout montant payable par Ryerson en lien avec toute somme impayée due au Client; e) Ryerson,
à son gré et à son choix, a le droit de retenir toutes les sommes d’argent versées par Le Client à titre de dommages-intérêts tirés
d’avance, et f) Ryerson peut se prévaloir des droits et des recours prévus par la loi en plus de tous les autres droits prévus dans
les présentes, lesquels droits et recours sont cumulatifs.`,
				],
			},
			{
				title: "ÉQUIPEMENT",
				body: [
					`a) Tout équipement, y compris les gabarits, les matrices et les outils, que Ryerson acquiert aux fins de la
production de marchandises pour le Client, est la propriété de Ryerson et demeurera en sa possession et sous son contrôle,
et Ryerson peut, à son entière discrétion, modifier cet équipement. b) Les matériaux ou l’équipement que le Client fournit à
Ryerson seront manipulés avec soin et entreposés par Ryerson tant qu’ils sont en sa possession. Si, pendant une période de
dix-huit (18) mois consécutifs, le Client ne passe aucune commande considérée comme acceptable par Ryerson pour des
marchandises fabriquées à partir de tel équipement ou de tels matériaux, Ryerson pourrait, moyennant un avis écrit au Client,
demander que le Client dispose à ses frais de l’équipement ou des matériaux. Si le Client omet de se conformer à cet avis dans
les trente (30) jours de la réception de l’avis écrit, Ryerson pourrait utiliser l’équipement ou les matériaux en question, ou en
disposer, à son gré, sans que Ryerson n’ait de responsabilité ou d’obligation envers le Client, et le Client s’engage par les
présentes à défendre, à indemniser et à tenir à couvert Ryerson (y compris les membres de son groupe et ses cessionnaires,
administrateurs, dirigeants, employés, mandataires et représentants) à l’égard de toute réclamation déposée par des tiers qui
découle de l’utilisation ou de la disposition des matériaux en question, ou qui s’y rapporte.`,
				],
			},
			{
				title: "ANNULATION",
				body: [
					`À la réception d’un avis écrit du Client, Ryerson doit annuler toute commande de la façon indiquée, sous
réserve du droit de Ryerson (ou de ses sous-traitants) de poursuivre le traitement des matériaux bruts ou finis afin de les rendre
à un point où l’interruption du processus engendre un minimum d’incidences et de frais pour Ryerson. Le Client est responsable
de tous les coûts rattachés à l’annulation et à l’achèvement du traitement des matériaux.`,
				],
			},
			{
				title: "ACCEPTATION",
				body: [
					`La présente Convention liera le Client en raison de son achat de marchandises auprès de Ryerson et ne peut
être modifiée qu’au moyen d’un document écrit, signé par Ryerson, et chacune des dispositions de la présente Convention
demeure en vigueur tant qu’elle n’est pas explicitement contredite par le document écrit susmentionné, s’il y a lieu. Si le Client
dépose un formulaire de commande renfermant des modalités contraires, ce formulaire de commande sera considéré
uniquement comme une confirmation de la commande et ne modifiera, ne prévaudra, ne complémentera, ni ne remplacera
d’aucune façon une disposition de la présente Convention. Le Client renonce à toute défense relativement à la validité ou à la
force exécutoire de la présente Convention en raison de sa transmission par voie électronique au Client. Le Client reconnaît
qu’il peut accéder à chaque URL mentionnée dans une soumission ou un autre document que Ryerson lui remet.`,
				],
			},
			{
				title: "GÉNÉRALITÉS",
				body: [
					`Ryerson peut céder ses droits et obligations prévus dans la présente Convention. Le Client ne peut céder ses
obligations prévues dans la présente Convention à un tiers sans le consentement préalable écrit de Ryerson. Si le Client change
son statut de constitution, tant le Client que ses successeurs continuent d’être liés par la présente Convention, et Ryerson
conserve les droits et les recours dont elle dispose en cas de défaut. Une déclaration, une affirmation ou une Convention
antérieure n’est exécutoire que si elle est mentionnée dans les présentes.`,
				],
			},
			{
				title: "UTILISATION À DES FINS NUCLÉAIRES",
				body: [
					`Les marchandises couvertes par la présente Convention et vendues par Ryerson
ne sont pas destinées et n’ont pas été certifiées pour l’application ou la fabrication relativement à l’utilisation ou à la
manipulation de matériel nucléaire ou à la construction, à l’exploitation ou à la maintenance d’installations nucléaires ou
aménagement (une « utilisation à des fins nucléaires ») et Ryerson ne donne aucune représentation ou garantie, expresse ou
implicite, à l’égard de la convenance ou la sécurité des marchandises pour l’utilisation à des fins nucléaires. Si le Client a
l’intention d’utiliser les marchandises, ou de permettre à toute autre personne de s’en servir, pour une utilisation à des fins
nucléaires, le Client sera le seul et unique responsable de s’assurer de la convenance et de la sécurité d’une telle utilisation
et/ou du sur classement des marchandises pour juger une telle application et sécurité. Ryerson renonce à toute responsabilité
relativement à tout dommage nucléaire ou autres dommages, blessure, perte de vie, contamination radioactive ou perte
économique résultant de ou étant présumé résulter de toute utilisation à des fins nucléaires des marchandises par le Client ou
toutes autres personnes, et le Client tiendra Ryerson à couvert de tout préjudice, l’indemnisera et le défendra (y compris les
membres de son groupe, ses ayants droit, ses administrateurs, ses dirigeants, ses employés, ses représentants et ses mandataires)
de tout(e) réclamation, perte, dommage, coût, poursuite, conséquence de poursuite, jugement, frais et responsabilité de quelque
nature que ce soit (y compris, mais non de façon limitative, les frais légaux ainsi que les coûts et les débours relatifs à la
défense) soit en contrat, action ou autre, qui, directement ou indirectement, sont de quelque façon que ce soit réputé être relié
à, découler ou provenir de cette utilisation à des fins nucléaires.`,
				],
			},
			{
				title: "RÉSOLUTION DE DIFFÉRENDS/LOI APPLICABLE",
				body: [
					`La validité, l’interprétation et l’exécution de la présente
Convention sont régies par les lois de la province de Québec et les lois fédérales du Canada qui s’y appliquent. Le Client s’en
remet irrévocablement à la compétence non exclusive des tribunaux de la province de Québec. Les honoraires et débours
d’avocats raisonnables sont accordés à la partie qui obtient gain de cause dans le cadre d’un litige.`,
				],
			},
			{
				title: "DIVISIBILITÉ",
				body: [
					`Si, dans un territoire, une disposition de la présente Convention ou son application à une partie ou dans
certaines circonstances est restreinte, interdite ou non exécutoire, cette disposition sera, à l’égard de ce territoire, sans effet
uniquement dans la mesure d’une telle restriction, interdiction ou inexigibilité, sans que les autres dispositions de la présente
Convention soient invalidées, et sans incidence sur la validité ou la force exécutoire de cette disposition dans un autre territoire
ni sur son application à d’autres parties ou dans d’autres circonstances.`,
				],
			},
			{
				title: "INTERNATIONAL",
				body: [
					`<em>La Convention des Nations Unies sur les contrats de vente internationale de marchandises </em> ne s’applique
pas à la présente Convention ni aux marchandises commandées ou livrées aux termes de la présente Convention. Chaque partie
accepte de se conformer à l’ensemble des lois et des règlements applicables en matière de corruption d’agents publics et de
lutte contre la corruption.`,
				],
			},
		],
	},
	bottom: [
		`Rév. 3/2025`,
	]
};

export const HeroContent: LanguageContent<PrivacyPolicyHeroContent> = {
	en: {
		title: "Terms and Conditions of Sale",
	},
	fr: {
		title: "Terms and Conditions of Sale",
	},
	es: {
		title: "Terms and Conditions of Sale",
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [USContent, CanadaContent, CanadaFrenchContent, HeroContent],
};
