import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { ContentSection } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export type PaidInternshipContent = {
	title: string;
	description: string;
	imageUrl: string;
	actionUrl: string;
	actionLabel: string;
};

export type PaidInternshipProps = {
	content: PaidInternshipContent;
	showNewInternLink?: boolean;
	background?: "primary";
};

const ImageContainer = styled.div`
	position: absolute;
	left: 50%;
	width: 50%;
	max-width: 720px;
	height: 800px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
`;

const TextContainer = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 50%;
	height: auto;
	padding: 100px 80px 100px 100px;
`;
const Spacer = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 50%;
	height: 800px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				display: block;
				width: 100%;
				height: 56px;
			}
		`;
	}}
`;

const PaidInternship: React.FC<PaidInternshipProps> = ({ content, showNewInternLink = false }) => {
	const { theme } = useTheme();

	const useNewInternLink = Boolean(process.env.GATSBY_CAREERS_URL_USA && showNewInternLink);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="40px">
					<ImageContainer
						css={css`
							background-image: url(${content.imageUrl});
						`}
					/>
					<TextContainer
						css={css`
							background-color: ${theme.colors.primary.darkerGray};
						`}
					>
						<Typography
							css={css`
								margin-top: 0;
								margin-bottom: 70px;
							`}
							type="tertiary"
							variant="h1"
						>
							{content.title}
						</Typography>
						<Typography
							css={css`
								margin-bottom: 50px;
							`}
							color={theme.colors.primary.lighterGray}
							variant="div"
							size="md"
						>
							{content.description}
						</Typography>
						<Link
							to={
								useNewInternLink
									? `${process.env.GATSBY_CAREERS_URL_USA}`
									: content.actionUrl
							}
							target={useNewInternLink ? "_blank" : "_self"}
							gatsby={!useNewInternLink}
						>
							<Button
								paddingType="relaxed"
								onClick={() => {}}
								label={content.actionLabel}
								size="lg"
							/>
						</Link>
					</TextContainer>
					<Spacer theme={theme} />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<img
					css={css`
						width: 100%;
						height: auto;
						display: block;
						margin-bottom: 30px;
						margin-top: 30px;
					`}
					src={content.imageUrl}
				/>
				<Typography
					size="md"
					variant="div"
					css={css`
						margin-bottom: 40px;
					`}
				>
					{content.description}
				</Typography>
				<Link
					to={
						useNewInternLink
							? `${process.env.GATSBY_CAREERS_URL_USA}`
							: content.actionUrl
					}
					target={useNewInternLink ? "_blank" : "_self"}
					gatsby={!useNewInternLink}
				>
					<Button
						onClick={() => {}}
						label={content.actionLabel}
						size="md"
						fullwidth={true}
					/>
				</Link>
				<Spacer theme={theme} />
			</Media>
		</>
	);
};

export default PaidInternship;
