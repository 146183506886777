import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";

export type Topics = {
	topic: string;
	link?: string;
};

export type EqualOpportunityContent = {
	title: string;
	description: string;
	imageUrl: string;
	mobileImageUrl: string;
	listTitle: string;
	list: Topics[];
};

export type EqualOpportunityProps = {
	content: EqualOpportunityContent;
	background?: "primary" | "secondary" | "tertiary";
};

const Description = styled.div`
	width: 460px;
	display: inline-block;
	margin-bottom: 50px;
	vertical-align: middle;
	box-sizing: border-box;
`;

const DesktopImage = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 100%;
	width: 425px;
	height: 480px;
`;

const DesktopSpacer = styled.div`
	display: block;
	width: 100%;
	height: 100px;
`;

const EqualOpportunity: React.FC<EqualOpportunityProps> = ({ content }) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="40px">
					<Flex direction="column" justifyContent="center">
						<FlexItem>
							<Flex
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<FlexItem>
									<DesktopImage>
										<img
											src={content.imageUrl}
											style={{
												maxWidth: "100%",
												maxHeight: "100%",
											}}
										/>
									</DesktopImage>
								</FlexItem>
								<FlexItem
									css={css`
										width: 460px;
									`}
								>
									<Typography
										variant="h2"
										css={css`
											margin-bottom: 25px;
										`}
									>
										{content.title}
									</Typography>
									<Description>
										<Typography size="md">{content.description}</Typography>
									</Description>
								</FlexItem>
							</Flex>
						</FlexItem>
					</Flex>
					<DesktopSpacer />
				</ContentSection>
			</Media>

			<Media lessThan="lg">
				<Typography
					size="md"
					variant="div"
					css={css`
						margin-top: 28px;
						margin-bottom: 20px;
					`}
				>
					{content.description}
				</Typography>
				<Flex direction="column">
					<FlexItem>
						<img
							src={content.mobileImageUrl}
							css={css`
								width: 100%;
								height: auto;
								margin-top: 15px;
								margin-bottom: 60px;
							`}
						/>
					</FlexItem>
				</Flex>
			</Media>
		</>
	);
};

export default EqualOpportunity;
