import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { FileExplorerContent } from "@components/WhyRyerson/RyersonCommitment/SubPage/FileExplorer";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Ryerson Gives Back - Ryerson",
		description:
			"Ryerson Gives Back is our corporate responsibility initiative designed to serve the communities in which we operate.",
		url: `/why-ryerson/ryerson-commitment/ryerson-gives-back`,
		company: true,
	},
	fr: {
		title: "Ryerson Gives Back - Ryerson",
		description:
			"Ryerson Gives Back is our corporate responsibility initiative designed to serve the communities in which we operate.",
		url: `/why-ryerson/ryerson-commitment/ryerson-gives-back`,
		company: true,
	},
	es: {
		title: "Ryerson Gives Back - Ryerson",
		description:
			"Ryerson Gives Back is our corporate responsibility initiative designed to serve the communities in which we operate.",
		url: `/why-ryerson/ryerson-commitment/ryerson-gives-back`,
		company: true,
	},
};

export const ByTheNumbersContent: LanguageContent<FileExplorerContent> = {
	en: {
		title: "By the Numbers",
		description:
			"Ryerson supports charitable organizations, including those that promote equality, good health, children's causes, educational advancement and community programs. We are proud to say that each year:",
		fileList: [
			{
				name: "2,900+ hours volunteered",
			},
			{
				name: "900+ employees participating",
			},
			{
				name: "50+ organizations served",
			},
			{
				name: "55+ locations",
			},
			{
				name: "12 markets served",
			},
			{
				name: "90+ unique events",
			},
		],
	},
	fr: {
		title: "Les chiffres",
		description:
			"Ryerson soutient des organismes de bienfaisance, notamment ceux qui ont pour but de promouvoir l’égalité, la santé, la cause des enfants, l’amélioration de l’éducation et les programmes communautaires. Nous sommes fiers de pouvoir dire que chaque année:",
		fileList: [
			{
				name: "Nous consacrons plus de 2 900 heures au bénévolat",
			},
			{
				name: "Plus de 900 employés participent",
			},
			{
				name: "Nous aidons plus de 50 organismes",
			},
			{
				name: "Nous faisons du bénévolat plus de 55 sites",
			},
			{
				name: "Nous aidons plus de 12 marchés",
			},
			{
				name: "Nous participons à plus de 90 évènements différents",
			},
		],
	},
	es: {
		title: "Ryerson en números",
		description:
			"Ryerson apoya a las organizaciones benéficas, incluyendo aquellas que promueven la igualdad, la buena salud, causas relacionadas con la infancia, mejoras educativas y programas comunitarios. Estamos orgullosos de decir que cada año:",
		fileList: [
			{
				name: "Hacemos más de 2900 horas de voluntariado",
			},
			{
				name: "Participan más de 900 empleados",
			},
			{
				name: "Ayudamos a más de 50 organizaciones",
			},
			{
				name: "Vamos a más de 55 ubicaciones",
			},
			{
				name: "Trabajamos con 12 mercados",
			},
			{
				name: "Asistimos a más de 90 eventos únicos",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [ByTheNumbersContent],
};
