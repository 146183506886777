import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Icon } from "@ryerson/frontend.assets";
import rgba from "polished/lib/color/rgba";
import styled from "@emotion/styled";
import { InOurDNAComponentProps } from "../InOurDNA";
import { Link } from "gatsby";

const HeaderSection = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${rgba(theme.colors.primary.secondaryBrand, 0.1)};
		`;
	}}
`;
const cardWrapper = css`
	height: auto;
	margin-top: 60px;
	margin-bottom: 40px;
`;
const BenefitCard = styled.div`
	height: 400px;
	position: relative;
	${(props: any) => {
		const { theme, width, index } = props;
		return css`
			width: ${width};
			background-color: ${theme.colors.primary.secondaryBrand};
			@media only screen and (min-width: ${theme.breakpoints.lg}) and (max-width: 1159px) {
				margin-bottom: ${width === "560px" && index === 0 ? "40px" : "inherit"};
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) and (max-width: 1119px) {
				margin-top: ${index === 2 ? "40px" : "inherit"};
			}
		`;
	}}
`;
const BenefitCardTitle = styled.div`
	position: absolute;
	top: 34px;
	left: 40px;
	width: 30%;
	z-index: 3;
	display: block;
`;
const BenefitCardDescription = styled.div`
	position: absolute;
	bottom: 34px;
	left: 40px;
	width: calc(100% - 80px);
	z-index: 1;
	display: block;
	font-size: 16px;
	line-height: 22px;
`;
const BenefitCardImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	display: block;
	transition: opacity 1s;
	${(props: any) => {
		const { backgroundUrl } = props;
		return css`
			background-image: url(${backgroundUrl});
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		`;
	}}
`;
const BenefitsCardPlus = styled.div`
	position: absolute;
	right: 30px;
	bottom: 40px;
	width: 40px;
	height: 40px;
	display: block;
	text-align: center;
	line-height: 42px;
	border-radius: 40px;
	${(props: any) => {
		return css`
			background-color: ${props.theme.colors.primary.primaryBrand};
		`;
	}}
`;
const Card = (props: any) => {
	const [imageShowing, setImageShowing] = React.useState(true);
	const [clicked, setClicked] = React.useState(false);

	const clickedCard = () => {
		if (clicked === true) {
			setClicked(false);
			setImageShowing(true);
		} else {
			setClicked(true);
			setImageShowing(false);
		}
	};
	const mousedCard = () => {
		if (clicked === false) {
			setImageShowing(false);
		}
	};
	const mousedOutCard = () => {
		if (clicked === false) {
			setImageShowing(true);
		}
	};

	return (
		<Link to={props.link}>
			<BenefitCard
				onClick={clickedCard}
				onMouseOver={mousedCard}
				onMouseOut={mousedOutCard}
				width={props.width}
				{...props}
			>
				<BenefitCardTitle>
					<Typography color={props.theme.colors.primary.white} size="lg" variant="div">
						{props.title}
					</Typography>
				</BenefitCardTitle>
				<BenefitCardDescription>
					<Typography color={props.theme.colors.primary.lightGray} variant="div">
						{props.context}
					</Typography>
				</BenefitCardDescription>
				<BenefitCardImage
					{...props}
					style={{ opacity: clicked === false && imageShowing === true ? 1 : 0 }}
				>
					<BenefitsCardPlus {...props}>
						<Icon
							icon="plus"
							size="xs"
							color={props.theme.colors.primary.white}
							circled={false}
						/>
					</BenefitsCardPlus>
				</BenefitCardImage>
			</BenefitCard>
		</Link>
	);
};

const InOurDNADesktop: React.FC<InOurDNAComponentProps> = (props) => {
	const { theme } = useTheme();
	return (
		<ContentSection type={props.background} vPadding="100px">
			<HeaderSection theme={theme}>
				<Typography variant="h2" css={{ marginBottom: "44px" }}>
					{props.content.title}
				</Typography>
			</HeaderSection>
			<Flex
				css={cardWrapper}
				justifyContent={theme.breakpoints.lg ? "space-evenly" : "space-between"}
				wrap="wrap"
			>
				{props.content.cards.map(function (content: any, index: number, width: any) {
					// eslint-disable-next-line no-lone-blocks
					{
						props.content.cards.length < 2 ? (width = "1160px") : (width = "400px");
					}
					return (
						<FlexItem key={index}>
							<Card theme={theme} {...content} width={width} index={index} />
						</FlexItem>
					);
				})}
			</Flex>
		</ContentSection>
	);
};
export default InOurDNADesktop;
