import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import Hero from "@components/Policies/PrivacyHero";
import Body from "@components/Policies/PrivacyBody";
import { useApplication } from "@ryerson/frontend.application";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import { Tabs, Tab } from "@ryerson/frontend.expansion";
import { ContentSection, Media } from "@ryerson/frontend.layout";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject, HeroContent, USContent, USContentNew, CanadaContent, CanadaFrenchContent, CanadaContentOntario, CanadaFrenchContentQuebec } from "@content/ryerson/terms-and-conditions";
import { useFeatureToggles } from "@ryerson/frontend.feature-toggles";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const { isFeatureEnabled } = useFeatureToggles();


	const isCCSurchargeFeatureEnabled =	isFeatureEnabled("2204-credit-card-surcharge");
	const _USContent = isCCSurchargeFeatureEnabled ? USContentNew : USContent;
	const _CanadaContent = isCCSurchargeFeatureEnabled ? CanadaContentOntario : CanadaContent;
	const _CanadaFrenchContent = isCCSurchargeFeatureEnabled ? CanadaFrenchContentQuebec : CanadaFrenchContent;

	const sections: Sections[] = [
		{
			cmp: <Body content={_USContent} />,
			title: "U.S. Terms and Conditions",
			maxHeight: "35000px",
		},
		{
			cmp: <Body content={_CanadaContent} />,
			title: "Canada Terms and Conditions ( English )",
			maxHeight: "35000px",
		},
		{
			cmp: <Body content={_CanadaFrenchContent} />,
			title: "Canada Terms and Conditions ( French )",
			maxHeight: "35000px",
		},
	];
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Hero content={HeroContent[language]} />
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0px">
					<Tabs type="tertiary">
						<Tab title="U.S. Terms and Conditions">
							<ContentSection hPadding="0px" type="tertiary" vPadding="45px">
								<Body content={_USContent} />
							</ContentSection>
						</Tab>
						<Tab title="Canada Terms and Conditions ( English )">
							<ContentSection hPadding="0px" type="tertiary" vPadding="45px">
								<Body content={_CanadaContent} />
							</ContentSection>
						</Tab>
						<Tab title="Canada Terms and Conditions ( French )">
							<ContentSection hPadding="0px" type="tertiary" vPadding="45px">
								<Body content={_CanadaFrenchContent} />
							</ContentSection>
						</Tab>
					</Tabs>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<AllSections sections={sections} />
			</Media>
		</Layout>
	);
};
