import React from "react";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import { Media } from "@ryerson/frontend.layout";
import { ContactUsCustomContent } from "@components/Careers/ContactUs/Content";
import ContactUsMobile from "@components/Shared/ContactUsRefactor/Mobile/ContactUs";
import InOurDNA from "@components/Shared/InOurDNA/InOurDNA";
import { InOurDNAContent } from "@components/Shared/InOurDNA/Content";
import ContactUsDesktop from "@components/Shared/ContactUsRefactor/Desktop/ContactUs";
import WorkingHero from "@components/WorkingAtRyerson/Hero/Hero";
import Benefits from "@components/WorkingAtRyerson/Benefits/Benefits";
import Meta from "@components/Shared/model/MetaHelmet";
import {
	MetaObject,
	JoinBenefitsContent,
	HeroContent,
	PaidInternshipsContent,
	EqualOpportunityEmployerContent,
} from "@content/ryerson/about-us/careers/working-at-ryerson";
import PaidInternship from "@components/TrainingAndDevelopment/PaidInternshipUpdated/PaidInternshipUpdate";
import EqualOpportunity from "@components/Shared/DiversityAndInclusion/EqualOpportunityUpdated/EqualOpportunityUpdated";

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const hasEnvCareersUrls = Boolean(
		process.env.GATSBY_CAREERS_URL_USA && process.env.GATSBY_CAREERS_URL_CANADA
	);

	const sections: Sections[] = [];
	sections.push({
		title: JoinBenefitsContent[language].title,
		cmp: <Benefits content={JoinBenefitsContent[language]} background="primary" />,
		maxHeight: "4000px",
	});
	sections.push({
		title: InOurDNAContent[language].title,
		cmp: <InOurDNA background="secondary" />,
		maxHeight: "4000px",
	});
	sections.push({
		title: PaidInternshipsContent[language].title,
		cmp: <PaidInternship background="primary" content={PaidInternshipsContent[language]} />,
	});
	sections.push({
		title: EqualOpportunityEmployerContent[language].title,
		cmp: (
			<EqualOpportunity
				background="primary"
				content={EqualOpportunityEmployerContent[language]}
			/>
		),
	});
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<WorkingHero content={HeroContent[language]} isCareers={true} />
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsMobile
					content={ContactUsCustomContent[language]}
					withImage={false}
					background="secondary"
					oneButtonVariant={!hasEnvCareersUrls}
					hasEnvCareersUrls={hasEnvCareersUrls}
				/>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContactUsDesktop
					content={ContactUsCustomContent[language]}
					withImage={true}
					background="secondary"
					oneButtonVariant={!hasEnvCareersUrls}
					hasEnvCareersUrls={hasEnvCareersUrls}
				/>
			</Media>
		</Layout>
	);
};
