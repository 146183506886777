import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection, SplitConfig } from "@ryerson/frontend.layout";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";
import { Button } from "@ryerson/frontend.button";
import { CareersButtons } from "@components/Shared/CareersButtons/CareersButtons";

export type WorkingHeroContent = {
	title: string;
	subTitle: string;
	description: string[];
	videoId: string;
	videoOverlay: string;
	thumbnailUrl: string;
	videoOverlayText: string;
	buttonLabel: string;
	buttonUrl: string;
};

export type WorkingHeroProps = {
	content: WorkingHeroContent;
	isCareers?: boolean;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const TextWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 460px;
				margin-bottom: 30px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 30px;
			}
		`;
	}}
`;

const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { theme, buttonOrientation = "left", buttonSize = "md" } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 560px;
				height: 420px;
				margin-top: 35px;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, false, buttonSize)};
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 230px;
				margin-bottom: 30px;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, true, buttonSize)};
			}
		`;
	}}
`;

const WorkingHero: React.FC<WorkingHeroProps> = ({ content, isCareers = false }) => {
	const { theme } = useTheme();

	const hasEnvCareersUrls =
		isCareers &&
		Boolean(process.env.GATSBY_CAREERS_URL_USA && process.env.GATSBY_CAREERS_URL_CANADA);

	const desktopHero: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.secondaryBrand,
				percentage: "80%",
			},
			{
				color: theme.colors.primary.white,
				percentage: "20%",
			},
		],
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="split" split={desktopHero}>
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 21px;
							margin-bottom: 25px;
						`}
					>
						{content.title}
					</Typography>
					<Grid>
						<Row>
							<Column lg={6}>
								<TextWrapper theme={theme}>
									<Typography variant="p" type="tertiary" size="lg">
										{content.subTitle}
									</Typography>
									{content.description.map((string: string, index: number) => {
										return (
											<Typography
												variant="p"
												type="tertiary"
												size="md"
												key={index}
											>
												{string}
											</Typography>
										);
									})}
								</TextWrapper>
								{hasEnvCareersUrls && <CareersButtons />}
								{!hasEnvCareersUrls && (
									<Link to={content.buttonUrl}>
										<Button
											label={content.buttonLabel}
											type="secondary"
											shape="rounded"
											paddingType="relaxed"
											onClick={() => {}}
											size="sm"
										/>
									</Link>
								)}
							</Column>
							<Column lg={6}>
								<VideoWrapper theme={theme} buttonOrientation="top-right">
									<Video
										videoId={content.videoId}
										imageUrl={content.thumbnailUrl}
										buttonOrientation="top-right"
										text={content.videoOverlayText}
									></Video>
								</VideoWrapper>
							</Column>
						</Row>
					</Grid>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 12px;
						`}
					>
						{content.title}
					</Typography>
					<Typography
						variant="p"
						type="tertiary"
						size="lg"
						css={{ width: "170px", marginBottom: "0" }}
					>
						{content.subTitle}
					</Typography>

					<VideoWrapper theme={theme} buttonOrientation="top-right">
						<Video
							videoId={content.videoId}
							imageUrl={content.thumbnailUrl}
							buttonOrientation="top-right"
							text={content.videoOverlayText}
						></Video>
					</VideoWrapper>
					<TextWrapper theme={theme}>
						{content.description.map((string: string, index: number) => {
							return (
								<Typography
									variant="p"
									color={theme.colors.primary.white}
									size="md"
									key={index}
								>
									{string}
								</Typography>
							);
						})}
					</TextWrapper>
					{hasEnvCareersUrls && <CareersButtons />}
					{!hasEnvCareersUrls && (
						<Link to={content.buttonUrl}>
							<Button
								label={content.buttonLabel}
								type="secondary"
								shape="rounded"
								paddingType="relaxed"
								onClick={() => {}}
								size="sm"
							/>
						</Link>
					)}
				</ContentSection>
			</Media>
		</>
	);
};

export default WorkingHero;
